import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
const routes = [
  {
    path: "/:lang/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/:lang/ramadan-offer",
    name: "Ramadan offer",
    component: Home,
    meta: {
      title: "Ramadan offer",
    },
  },
  {
    path: "/:lang/seminar",
    name: "Seminar",
    component: Home,
    meta: {
      title: "Seminar",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/en/",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
