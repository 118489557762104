<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body m-0">
            <div class="row d-flex justify-content-end px-4">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              ></button>
            </div>
            <div>
              <slot name="modal-body"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { getters } from "../store";
export default {
  name: "ModalHuedal",
  computed: {
    license() {
      return getters.license();
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style>
button.btn-close {
  margin-top: 0.7rem;
  width: 20px !important;
  height: 20px !important;
}
.modal-content {
  border-radius: 15px !important;
  border: none !important;
  background-color: #f1f1f1 !important;
  padding-bottom: 2.5rem;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-body .selected,
.modal-body .lang:hover {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px #cccccc;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
