export default {
  en: {
    markets: {
      eu: "Markets",
      com: "Markets",
      social: "Social",
      xtend: "Xtend",
      ramadan: "Markets"
    },
    accounts: `Accounts`,
    licenses: `Licenses`,
    security: `Security`,
    platforms: `Platforms`,
    register: `Register`,
    login: "Login",
    selectYourLanguage: "Select Your Language",
  },
  fr: {
    markets: {
      eu: "Machés",
      com: "Machés",
      social: "Social",
      xtend: "Xtend",
    },
    accounts: `Comptes`,
    licenses: `Licences`,
    security: `Sécurité`,
    platforms: `Plateforme`,
    register: "S'enregistrer",
    login: "Se connecter",
    selectYourLanguage: "Sélectionnez votre langue",
  },
  it: {
    markets: {
      eu: "Mercati",
      com: "Mercati",
      social: "Sociale",
      xtend: "Xtend",
    },
    accounts: `Conti`,
    licenses: `Licenze`,
    security: `Sicurezza`,
    platforms: `Piattaforme`,
    register: "Registrati",
    login: "Login",
    selectYourLanguage: "Choisissez votre langue",
  },
  de: {
    markets: {
      eu: "Märkte",
      com: "Märkte",
      social: "Sozial",
      xtend: "Xtend",
    },
    accounts: `Konten`,
    licenses: `Lizenzen`,
    security: `Sicherheit`,
    platforms: `Plattformen`,
    register: "Registrieren",
    login: "Einlogen",
    selectYourLanguage: "Wählen Sie Ihre Sprache",
  },
  ch: {
    markets: {
      eu: "市场",
      com: "市场",
      social: "社会的",
      xtend: "Xtend",
    },
    accounts: `帐户`,
    licenses: `许可证和法规`,
    security: `Sicherheit`,
    platforms: `平台`,
    register: "登记",
    login: "登录",
    selectYourLanguage: "选择您的语言",
  },
  es: {
    markets: {
      eu: "Mercados",
      com: "Mercados",
      social: "Social",
      xtend: "Xtend",
    },
    accounts: `Cuentas`,
    licenses: `Licencias`,
    security: `Seguridad`,
    platforms: `Plataformas`,
    register: `Registro`,
    login: "Acceso",
    selectYourLanguage: "Seleccione su idioma",
  },
  vn: {
    markets: {
      eu: "Thị trường",
      com: "Thị trường",
      social: "Xã hội",
      xtend: "Xtend",
    },
    accounts: `Tài khoản`,
    licenses: `Giấy phép`,
    security: `Bảo vệ`,
    platforms: `Nền tảng`,
    register: `Đăng ký`,
    login: "Đăng nhập",
    selectYourLanguage: "Chọn ngôn ngữ của bạn",
  },
  arb: {
    markets: {
      eu: "الأسواق",
      com: "الأسواق",
      social: "Social",
      egypt: "الأسواق",
    },
    accounts: `الحسابات`,
    licenses: `التراخيص`,
    security: `الأمن`,
    platforms: `المنصات`,
    register: `إنشاء حساب`,
    login: `تسجيل الدخول`,
    selectYourLanguage: "اختر لغتك",
  },
};
