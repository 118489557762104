<template>
  <div class="container" id="accounts">
    <div class="row d-flex justify-content-center my-5">
      <h2 class="col-12 title text-center">{{ $t("accountTypes") }}</h2>
      <hr class="blue" />
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-xl-12 col-lg-9 col-md-10 col-sm-7 col-12">
        <div class="row d-flex justify-content-center">
          <div
            v-for="(account, idx) in $tm('commissions')"
            :key="idx"
            class="col-xl-3 col-md-6 col-12 d-flex justify-content-center mb-xl-0 mb-4"
          >
            <div :class="`account-card ${account.class} col-12`">
              <div class="text-center py-3">
                <span class="fs-4 fw-bold text-capitalize">{{
                  account.name
                }}</span>
              </div>
              <div
                :class="`account-price ${account.class}  text-center text-white py-3`"
              >
                <div>
                  <span class="text-white fs-5 me-1">$</span
                  ><span class="text-white fs-2">{{ account.minDeposit }}</span>
                </div>

                <p class="text-white mb-0">{{ $t("minimumFirst") }}</p>
              </div>
              <div class="account-table px-2">
                <div :class="`col-6 ${textEnd}`">
                  {{ $t("currency") }}
                </div>
                <div
                  v-if="selectedLang !== 'ch' && selectedLang !== 'vn'"
                  :class="`col-6 ${textEnd} fw-bold`"
                >
                  {{ license == "global" ? "USD-EUR" : "USD-EUR-GBP-CHF" }}
                </div>
                <div
                  v-if="selectedLang == 'ch' || selectedLang == 'vn'"
                  :class="`col-6 ${textEnd} fw-bold`"
                >
                  USD
                </div>
              </div>
              <div class="account-table px-2">
                <div :class="`col-6 ${textEnd}`">
                  {{ $t("commission") }}
                </div>
                <div :class="`col-6 ${textEnd} fw-bold`">
                  {{ account.commission }}
                </div>
              </div>
              <div class="account-table px-2">
                <div :class="`col-6 ${textEnd}`">
                  {{ $t("spread") }}
                </div>
                <div :class="`col-6 ${textEnd} fw-bold`">
                  {{ account.spread }}
                </div>
              </div>
              <div class="account-table px-2">
                <div :class="`col-6 ${textEnd}`">
                  {{ $t("leverage") }}
                </div>
                <div
                  v-if="selectedLang !== 'vn'"
                  :class="`col-6 ${textEnd} fw-bold`"
                >
                  {{ account.leverage }}
                  {{ license == "global" ? "1:500" : "1:30" }}
                </div>
                <div
                  v-if="selectedLang == 'vn'"
                  :class="`col-6 ${textEnd} fw-bold`"
                >
                  {{ account.leverage }}
                  1:1000
                </div>
              </div>
              <div class="account-table px-2">
                <div :class="`col-6 ${textEnd}`">
                  {{ $t("tradeVol") }}
                </div>
                <div :class="`col-6 ${textEnd} fw-bold`">
                  0.01 {{ $t("lot") }}
                </div>
              </div>
              <div class="text-center mb-4 mt-2">
                <a
                  :href="registerURL"
                  target="_blank"
                  class="btn trio text-white"
                  >{{ $t("openAccount") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "Accounts-u",
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    selectedLang() {
      return getters.selectedLang();
    },
    checkArb() {
      return getters.checkArb();
    },
    textEnd() {
      if (this.checkArb) {
        return "text-end";
      } else {
        return "";
      }
    },
  },
};
</script>
