<template>
  <router-view></router-view>
  <div
    class="
      disclaimer
      fixed-bottom
      bg-dark
      text-center
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <span
      class="text-white"
      v-html="$t('riskWarning', { license: license })"
    ></span>
  </div>
</template>

<script>
import { getters, mutations } from "./store";
export default {
  name: "App",
  mounted() {
    mutations.setLandingType();
    document.title = `TrioMarkets | ${this.checkLanding}`;
  },
  computed: {
    license() {
      return getters.license();
    },
    checkLanding() {
      return getters.landingType()?.title;
    },
  },
};
</script>

<style></style>
