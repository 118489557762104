<template>
  <div
    id="speakers"
    class="d-flex flex-column justify-content-center align-items-center my-5"
  >
    <div class="d-flex flex-column align-items-center mt-5">
      <h2 class="title text-center mt-5">Speakers:</h2>
    </div>
    <hr class="blue" />
    <div class="row d-flex justify-content-center mt-4 mb-5 pb-5">
      <div class="col-md-12 col-12">
        <div
          class="card speakers-box mb-5"
          style="max-width: 650px"
          v-for="(speaker, idx) in speakers"
          :key="idx"
        >
          <div class="row g-0 p-5">
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title text-primary fw-bold">
                  {{ speaker.name }}
                </h5>
                <p class="fs-6 fw-bold">{{ speaker.title }}</p>
                <p class="card-text">{{ speaker.bio }}</p>
              </div>
            </div>
            <div class="col-md-4">
              <img
                :src="`/img/seminar/${speaker.image}`"
                class="img-fluid"
                alt="mauro"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      speakers: [
        {
          name: "Mauro Mazzaco",
          title: "Head of Brokerage",
          bio: "Mauro Mazzacco, accomplished Head of Brokerage, developed his passion for finance in Italy from a young age. He spotlights opportunities in market fluctuations and fosters an inclusive, collaborative culture. Valuing collective knowledge, he guides the firm to attract clients and outperform benchmarks.",
          image: "mauro.png",
        },
        {
          name: "Natasha Kassar",
          title: "CEO of MENA region",
          bio: "Natasha Kassar, seasoned with business development experience in the financial industry `for the past 12 years. Holding various positions and building her way up, she has served as Head of Business Development , Senior Business Developer, retention and conversion manager,  focusing in building markets from scratch such as Africa and MENA.",
          image: "natasha.png",
        },
      ],
    };
  },
};
</script>

<style>
.speakers-box {
  border: none !important;
  border-radius: 10px !important;
  background: #f2f3f4 !important;
  box-shadow: 0px 0px 25px rgba(73, 73, 73, 0.199) !important;
}
</style>
