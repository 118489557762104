<template>
  <div class="form">
    <Form @submit="register" :validation-schema="schema" class="reg-form">
      <Field name="fname" v-model="accountData.fname" v-slot="{ field, meta }">
        <input
          v-bind="field"
          :class="{
            'form-control': true,
            'is-invalid': !meta.valid && meta.touched,
          }"
          :placeholder="$t('fname')"
        />
      </Field>
      <ErrorMessage name="fname" class="error" />
      <Field name="lname" v-model="accountData.lname" v-slot="{ field, meta }">
        <input
          v-bind="field"
          :class="{
            'form-control': true,
            'is-invalid': !meta.valid && meta.touched,
          }"
          :placeholder="$t('lname')"
        />
      </Field>
      <ErrorMessage name="lname" class="error" />
      <Field name="email" v-model="accountData.email" v-slot="{ field, meta }">
        <input
          v-bind="field"
          :class="{
            'form-control': true,
            'is-invalid': !meta.valid && meta.touched,
          }"
          :placeholder="$t('email')"
        />
      </Field>
      <ErrorMessage name="email" class="error" />
      <Field
        name="country"
        v-model="accountData.country_id"
        v-slot="{ field, meta }"
      >
        <select
          :class="{
            'form-control': true,
            'is-invalid': !meta.valid && meta.touched,
          }"
          v-bind="field"
        >
          <option value="0" disabled="disabled" selected="selected">
            {{ $t("country") }}
          </option>
          <option
            v-for="country in countries"
            :value="country.country_id"
            :key="country.country_id"
          >
            {{ country.name }}
          </option>
        </select>
      </Field>
      <ErrorMessage name="country" class="error" />
      <Field
        name="phone"
        type="number"
        v-model="accountData.tel_number"
        v-slot="{ field, meta }"
      >
        <input
          v-bind="field"
          :class="{
            'form-control': true,
            'is-invalid': !meta.valid && meta.touched,
          }"
          :placeholder="$t('phone')"
        />
      </Field>
      <ErrorMessage name="phone" class="error" />
      <div class="eula mt-3">
        <Field
          name="eula"
          type="checkbox"
          v-model="eula"
          :class="`form-check-input p-0 ${checkArb ? 'ms-2' : 'me-2'}`"
        />
        <small
          v-html="
            $t('terms', {
              license: license,
            })
          "
        ></small>
      </div>
      <ErrorMessage name="eula" class="error text-center" />
      <div class="buttonset text-center">
        <button :disabled="isSubmitted" :class="`btn mt-5 ${checkLanding == 'ramadan' ? 'btn-gold outline-gold form-btn':'btn-blue outline form-btn'}`">
          <span class="text-uppercase" v-show="!isSubmitted">{{
            $t("btn")
          }}</span>
          <div
            v-show="isSubmitted"
            class="spinner-border text-white"
            role="status"
          ></div>
        </button>
      </div>
    </Form>
    <modal v-show="showModal" @close="showModal = false">
      <template v-slot:modal-body>
        <div class="container-fluid text-center">
          <div class="pt-4">
            <img
              :src="'/img/' + response.img"
              alt="response_image"
              class="mb-2"
            />
            <h4
              :class="[
                'fs-2',
                response.class === 'danger' ? 'text-danger' : 'text-success',
              ]"
            >
              {{ response.heading }}
            </h4>
          </div>
          <div>
            <p class="fs-4">{{ response.message }}</p>
          </div>
          <div>
            <p class="fs-5">
              <b>{{ response.verifyEmailText }}</b>
            </p>
          </div>
          <div>
            <button
              v-if="response.class == 'danger'"
              @click="retry()"
              class="btn btn-danger mb-3"
            >
              {{ $t("tryAgain") }}
            </button>
            <button v-else @click="redirect()" class="btn btn-success mb-3">
              {{ response.proceed }}
            </button>
            <div class="contact">
              <span>{{ $t("needHelp") }} </span>
              <a href="https://triomarkets.com/contact" target="_blank">{{
                $t("contactUs")
              }}</a>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import { getters } from "../store";
import { object, string } from "yup";
import Modal from "./Modal.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
export default {
  name: "Form-Huerm",
  data() {
    return {
      accountData: {
        fname: "",
        lname: "",
        email: "",
        tel_number: "",
        country_id: 0,
        httpref: window.location.href,
        license: "",
        sidc: "",
      },
      eula: false,
      isSubmitted: false,
      showModal: false,
      createdUserId: "",
      response: {
        heading: "Your registration was successful!",
        message: "",
        verifyEmailText: "",
        img: "smile.svg",
        class: "success",
        proceed: this.$t("loginBtn"),
      },
    };
  },
  components: {
    Field,
    Form,
    ErrorMessage,
    Modal,
  },
  computed: {
    countries() {
      return getters.countries();
    },
    license() {
      return getters.license();
    },
    sidc() {
      return this.$route.query.sidc;
    },
    selectedLanguage() {
      return this.$root.$i18n.locale;
    },
    checkArb() {
      return getters.checkArb();
    },
    schema() {
      return object({
        fname: string()
          .matches(/^[A-Za-z ]+$/i, {
            message: this.$t("rnameLatin"),
            excludeEmptyString: true,
          })
          .required(this.$t("rname")),
        lname: string()
          .matches(/^[A-Za-z ]+$/i, {
            message: this.$t("rlastLatin"),
            excludeEmptyString: true,
          })
          .required(this.$t("rlast")),
        email: string().required(this.$t("remail")).email(),
        country: string()
          .required(this.$t("rcountry"))
          .notOneOf(["0", 0], this.$t("rcountry")),
        phone: string().required(this.$t("rphone")),
        eula: string().oneOf(["on", null], this.$t("pleaseAccept")),
      });
    },
    checkLanding() {
      return getters.landingType()?.type;
    },
  },
  watch: {
    license: {
      handler(license) {
        if (license === "global") {
          this.accountData.license = license;
        }
      },
      immediate: true,
    },
    sidc: {
      handler(sidc) {
        this.accountData.sidc = sidc;
      },
      immediate: true,
    },
    $route() {
      this.accountData.httpref = window.location.href;
    },
  },
  methods: {
    async register() {
      this.isSubmitted = true;
      await axios({
        method: "post",
        url: "https://new-api.triomarkets.com/auth/register",
        data: this.accountData,
      })
        .then(({ data }) => {
          this.showModal = true;
          this.isSubmitted = false;
          if (data.data.length > 0) {
            this.response = {
              heading: "Your registration was successful!",
              message: data.info.message,
              verifyEmailText:
                "Please check your email to validate your registration and get access to your client zone.",
              img: "smile.svg",
              class: "success",
              proceed: this.$t("loginBtn"),
            };
            this.createdUserId = data.data[0].user;
          } else {
            this.response = {
              heading: "Sorry your registration failed!",
              message:
                data.info.message === "invalid fname"
                  ? "Name can only contain letters!"
                  : data.info.message === "invalid lname"
                  ? "Last name can only contain letters!"
                  : data.info.message,
              img: "sad.svg",
              class: "danger",
              proceed: "",
            };
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    retry() {
      this.showModal = false;
    },
    redirect() {
      this.response.proceed = "Redirecting...";
      window.location.replace(
        `https://secure.triomarkets.${this.license === "eu" ? "eu" : "com"}/${
          this.selectedLanguage
        }/login`
      );
      // window.location.replace(
      //   `https://my.triomarkets.${this.license === "eu" ? "eu" : "com"}/${
      //     this.selectedLanguage
      //   }/validation-required?vm=1&CU=${this.createdUserId}`
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  max-width: 30%;
  min-height: 70%;
  background-color: rgba(95, 95, 95, 0.75);
  padding: 2rem;
  border-radius: 10px;
  @media screen and (max-width: 1600px) {
    max-width: 40%;
  }
  @media screen and (max-width: 1200px) {
    max-width: 97%;
    min-height: 40%;
  }
  @media screen and (max-width: 768px) {
    max-width: 95%;
  }
  @media screen and (max-width: 574px) {
    max-width: 87%;
  }
  input,
  select {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #fff;
    margin-bottom: 0.1rem;
    font-size: 0.85rem;
    color: #fff;
    border-radius: 0px;
    padding: 0.75rem 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #fff;
    }
    &.form-check-input {
      border: 1px solid #fff !important;
      max-height: 13px !important;
      margin-top: 0.3rem;
    }
  }
  .error {
    color: red;
  }
  select:focus {
    color: #00162c !important;
  }
  small {
    font-size: 15px;
    color: #fff;
    a {
      color: #fff !important;
      &:visited {
        color: #fff;
      }
    }
  }
  .invalid-feedback {
    display: block !important;
  }
}
button {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.registerBtn {
  border: 3px solid #4c585f;
  outline: 1px solid #007bff;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.contact {
  a,
  small {
    color: black !important;
  }
}
</style>
