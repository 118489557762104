
export default {
  en: {
    rname: `Name is required`,
    rnameLatin: "Name may only contain alphabetic characters",
    rlast: `Last name is required`,
    rlastLatin: "Surname may only contain alphabetic characters",
    remail: `Email is required`,
    rcountry: `Country is required`,
    rphone: `Phone is required`,
    pleaseAccept: `Please accept our policies.`,
    tryAgain: `Try again`,
    needHelp: `Need help?`,
    contactUs: `Contact us!`,
    fname: "First Name",
    lname: "Last Name",
    email: "Email Address",
    country: "Country",
    phone: "Phone",
    terms: (ctx) =>
      `I hereby acknowledge and agree that the company will keep and process my Personal Data for regulatory purpose in accordance with GDPR*. I have read, understood and accepted TrioMarkets  <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Client Agreement</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow"> Portfolio Management Agreement</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Privacy Policy</a>`,
    btn: "REGISTER NOW",
    loginBtn: "Go to Login",
  },
  fr: {
    rname: `Prénom obligatoire`,
    rnameLatin: "Le nom ne peut contenir que des caractères alphabétiques",
    rlast: `Nom obligatoire`,
    rlastLatin:
      "Le nom de famille ne peut contenir que des caractères alphabétiques",
    remail: `Email obligatoire`,
    rcountry: `Pays obbligatoria`,
    rphone: `Numéro de téléphone obligatoire`,
    pleaseAccept: `Please accept our policies.`,
    tryAgain: `Try again`,
    needHelp: `Need help?`,
    contactUs: `Contact us!`,
    fname: "Nom",
    lname: "Nom de famille",
    email: "Adresse e-mail",
    country: "Pays",
    phone: "Téléphone",
    terms: (ctx) =>
      `Je reconnais et accepte par la présente que la société conservera et traitera mes données personnelles à des fins réglementaires conformément au RGPD *. J'ai lu, compris et accepté <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">L'Accord client</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">L'Accord de gestion de portefeuille</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">La politique de confidentialité</a> de TrioMarkets`,
    btn: "S'inscrire",
    loginBtn: "Connectez-vous",
  },
  it: {
    rname: `Nome obbligatorio`,
    rnameLatin: "Il nome può contenere solo caratteri alfabetici",
    rlast: `Cognome obbligatorio`,
    rlastLatin: "Il cognome può contenere solo caratteri alfabetici",
    remail: `Email obbligatoria`,
    rcountry: `Paese obbligatoria`,
    rphone: `Telefono obbligatorio`,
    pleaseAccept: `Please accept our policies.`,
    tryAgain: `Try again`,
    needHelp: `Need help?`,
    contactUs: `Contact us!`,
    fname: "Nome",
    lname: "Cognome",
    email: "Indirizzo Email",
    country: "Paese",
    phone: "Telefono",
    terms: (ctx) =>
      `Con la presente sono a conoscenza e accetto che la Società conserverà e tratterà i miei dati personali a fini regolamentari in conformità con il GDPR*. Ho letto, compreso e accettato il <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Contratto cliente</a>, il <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow"> L'Informativa sulla privacy</a> di TrioMarkets.`,
    btn: "REGISTRAZIONE",
    loginBtn: "VAI AL LOGIN",
  },
  de: {
    rname: `Name ist erforderlich`,
    rnameLatin: "Der Name darf nur Buchstaben enthalten",
    rlast: `Nachname ist erforderlich`,
    rlastLatin: "Der Nachname darf nur Buchstaben enthalten",
    remail: `E-Mail ist erforderlich`,
    rcountry: `Land ist erforderlich`,
    rphone: `Telefonnummer ist erforderlich`,
    pleaseAccept: `Please accept our policies.`,
    tryAgain: `Try again`,
    needHelp: `Need help?`,
    contactUs: `Contact us!`,
    fname: "Vorname",
    lname: "Nachname",
    email: "E-Mail Adressemail",
    country: "Land",
    phone: "Telefono",
    terms: (ctx) =>
      `Ich erkenne hiermit an und bin damit einverstanden, dass das Unternehmen meine personenbezogenen Daten gemäß GDPR * zu regulatorischen Zwecken aufbewahrt und verarbeitet. Ich habe die TrioMarkets <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Kundenvereinbarung</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">die Portfolioverwaltungsvereinbarung</a>,'
      <a href="https://triomarkets.${
        ctx.named("license") === "eu" ? "eu" : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow"> die Datenschutzbestimmungen gelesen</a> verstanden und akzeptiert`,
    btn: "Registrieren",
    loginBtn: "einloggen",
  },
  es: {
    rname: `Se requiere el nombre`,
    rnameLatin: "El nombre solo puede contener caracteres alfabéticos",
    rlast: `Se requiere apellido`,
    rlastLatin: "El apellido solo puede contener caracteres alfabéticos",
    remail: `Correo electronico es requerido`,
    rcountry: `Country is required`,
    rphone: `El país es obligatorio`,
    pleaseAccept: `Por favor acepte nuestras políticas.`,
    tryAgain: `Intentar otra vez`,
    needHelp: `¿Necesitas ayuda?`,
    contactUs: `¡Contáctenos!`,
    fname: "Primer nombre",
    lname: "Apellido",
    email: "Dirección de correo electrónico",
    country: "País",
    phone: "Teléfono",
    terms: (ctx) =>
      `Por la presente reconozco y acepto que la empresa conservará y procesará mis datos personales con fines normativos de conformidad con el RGPD*. He leído, entendido y aceptado TrioMarkets <a href="https://triomarkets.${
        ctx.named("license") === "eu"
          ? 'eu'
          : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Acuerdo del Cliente</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu"
          ? 'eu'
          : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow"> Contrato de Gestión de Cartera</a>, <a href="https://triomarkets.${
        ctx.named("license") === "eu"
          ? 'eu'
          : "com"
      }com/en/company/legal-documents" target="_blank" rel="nofollow">Política de Privacidad</a>`,
    btn: "REGÍSTRATE AHORA",
    loginBtn: "Ir a Iniciar sesión",
  },
  ch: {
    rname: `名氏为必填项`,
    rnameLatin: "名称只能包含字母字符",
    rlast: `姓氏为必填项`,
    rlastLatin: "姓氏只能包含字母字符",
    remail: `电子邮件是必需填项`,
    rcountry: `国家/地区为必填项`,
    rphone: `电话号码为必填项`,
    pleaseAccept: `Please accept our policies.`,
    tryAgain: `Try again`,
    needHelp: `Need help?`,
    contactUs: `Contact us!`,
    fname: "名氏",
    lname: "姓氏",
    email: "电子邮件",
    country: "国家",
    phone: "电话号码",
    terms: `我在此承认并同意，公司将根据 GDPR* 出于监管目的保留和处理我的个人数据。我已阅读、理解并接受 TrioMarkets™
      <a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">客户协议</a>,  <a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow"> 投资组合管理协议</a>, <a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow"> 隐私政策. </a>`,
    btn: "登记",
    loginBtn: "登录",
  },
  vn: {
    rname: `Tên là bắt buộc`,
    rnameLatin: "Tên chỉ có thể chứa các ký tự chữ cái",
    rlast: `Họ là bắt buộc`,
    rlastLatin: "Họ chỉ có thể chứa các ký tự chữ cái",
    remail: `Email thì cần thiết`,
    rcountry: `Quốc gia là bắt buộc`,
    rphone: `Điện thoại là bắt buộc`,
    pleaseAccept: `Vui lòng chấp nhận các chính sách của chúng tôi.`,
    tryAgain: `Thử lại`,
    needHelp: `Cần giúp đỡ?`,
    contactUs: `Liên hệ chúng tôi!`,
    fname: "Tên đầu tiên",
    lname: "Họ",
    email: "Địa chỉ email",
    country: "Quốc gia",
    phone: "Điện thoại",
    terms:`Tôi xác nhận và đồng ý rằng công ty sẽ lưu giữ và xử lý Dữ liệu Cá nhân của tôi cho mục đích pháp lý phù hợp với GDPR *. Tôi đã đọc, hiểu và chấp nhận TrioMarkets™ <a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">Thỏa thuận khách hàng</a>,<a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow"> Thỏa thuận quản lý danh mục đầu tư</a>, <a style="color: white;" href="https://triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">Chính sách bảo mật</a>`,
    btn: "ĐĂNG KÝ NGAY",
    loginBtn: "Chuyển đến Đăng nhập",
  },
  arb: {
    rname: `الإسم إلزامي`,
    rnameLatin: "الإسم يجب أن يحتوي على أحرف أبجدية فقط",
    rlast: `الكنية إلزامي`,
    rlastLatin: "الإسم يجب أن يحتوي على أحرف أبجدية فقط",
    remail: `البريد الإلكتروني إلزامي`,
    rcountry: `الدولة إلزامي`,
    rphone: `الهاتف إلزامي`,
    pleaseAccept: `يجب أن توافق على الشروط والأحكام`,
    tryAgain: `حاول مجددا`,
    needHelp: `احتاج مساعدة؟`,
    contactUs: `تواصل معنا!`,
    fname: "الاسم الأول",
    lname: "الاسم الأخير",
    email: "البريد الإلكتروني",
    country: "الدولة",
    phone: "رقم الهاتف",
    terms:`أقر بموجب هذا وأوافق على أن الشركة ستحتفظ ببياناتي الشخصية وتعالجها لأغراض تنظيمية وفقًا للائحة العامة لحماية البيانات (GDPR) *. لقد قرأت وفهمت وقبلت TrioMarkets<a href="https://www.triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">إتفاقية العميل</a>, <a href="https://www.triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">إتفاقية إدارة المحفظة<a href="https://www.triomarkets.com/en/company/legal-documents" target="_blank" rel="nofollow">سياسة الخصوصية</a>`,
    btn: "سجل الان",
    loginBtn: "انتقل إلى تسجيل الدخول",
  },
};
