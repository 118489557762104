export default {
  en: {
    ramadanTitile: `Fast-track your financial goals this Ramadan with our 25% Credit Bonus limited offers! The rules are easy and simple:`,
    ramadanPoints: [
      { name: "Open a live <br> Account", img: "ram1" },
      { name: "Make your <br> Deposit", img: "ram2" },
      { name: "Get 25% <br> Credit Bonus", img: "ram3" },
    ],
    ramadanFirstTable: `What is the minimum and maximum deposit I can make? `,
    depositTable: `Deposit`,
    creditBonus: `25% Credit Bonus `,
    ramadanSecondTable: `Care to make it much more interesting? Trade certain amount of lots to receive Cash Reward!`,
    lotsTraded: `Lots to Be Traded`,
    cashReward: `Cash Reward `,
    investingRamadan: `Start investing in your future this Ramadan! TrioMarkets&trade; is here to help you`,
  },
  arb: {
    ramadanTitile: `قم بتتبع أهدافك المالية بسلاسة خلال شهر رمضان المبارك من خلال عروضنا المحدودة لمكافأة الائتمان بنسبة %25! القواعد سهلة وبسيطة: `,
    ramadanPoints: [
      { name: "افتح حساب <br> حقيقي", img: "ram1" },
      { name: "قم <br> بالإيداع", img: "ram2" },
      { name: "احصل على %25 <br> مكافئة ائتمانية", img: "ram3" },
    ],
    ramadanFirstTable: `ما هو الحد الأدنى والحد الأقصى للإيداع؟ `,
    depositTable: `الإيداع`,
    creditBonus: `25% مكافئة ائتمانية `,
    ramadanSecondTable: `هل ترغب في جعل الأمر أكثر إثارة؟ تداول كمية معينة من الوحدات لتحصل على مكافأة نقدية! `,
    lotsTraded: `الوحدات المتداولة `,
    cashReward: `المكافأة النقدية `,
    investingRamadan: `ابدأ الاستثمار في مستقبلك في الشهر الكريم! تريوماركتس موجودة هنا للمساعدة`,
  },
};
