let risk = "74%";
let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    title: {
      eu: `Discover <strong>TrioMarkets&trade;</strong>`,
      com: "Discover <strong>TrioMarkets&trade;</strong>",
      social: "Join the Social Trading revolution!",
      xtend: "Discover TrioXtend exclusively with TrioMarkets&trade;",
      ramadan:
        "Unlock your Financial Potential this Ramadan, Trade with TrioMarkets&trade;!",
      seminar: "Join our Traders Boot Camp with <strong>TrioMarkets</strong>",
    },
    points: {
      eu: [
        "Join a Global True ECN Leading Forex Broker",
        "Licensed Investment Advice & Portfolio Management",
        "Fully regulated within EU",
        "Ultra Tight Spreads",
      ],
      com: [
        "Join a Global True ECN Leading Forex Broker",
        "Licensed Investment Advice",
        "Regulated by the Financial Services Commission of Mauritius",
        "Ultra Tight Spreads",
      ],
      social: [
        "Trade with confidence in a profitable and automated enviroment",
        "Choose the most profitable traders and copy their strategies",
        "Become part of a trusted trading community and multiply your chances of success",
      ],
      xtend: [
        "FXBlue Custom EA's/Indicators",
        "Innovative Management Tools",
        "Join a Global True ECN Leading Forex Broker",
        "Ultra Tight Spreads",
      ],
      ramadan: [
        "Create a live account",
        "Make your generous deposit",
        "Get 25% Credit Bonus",
      ],
      seminar: [
        `<div class="d-flex mt-2"><strong class="text-primary">Venue: </strong> <div class="mb-1 ms-1">Sofitel Downtown Dubai</div></div>`,
        `<div class="d-flex mt-2"><strong class="text-primary">Location: </strong> <div class="mb-1 ms-1">Sheikh Zayed Rd; Downtown Dubai  Dubai-UAE</div></div>`,
        `<div class="d-flex mt-2"><strong class="text-primary">Telephone: </strong> <div class="mb-1 ms-1">+971 4503666</div></div>`,
        `<div class="d-flex mt-2"><strong class="text-primary">Date: </strong/><div class="mb-1 ms-1">Friday 6th October</div></div>`,
        `<div class="d-flex mt-2"><strong class="text-primary">Time: </strong/><div class="mb-1 ms-1">4-7pm</div></div>`,
      ],
    },
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min Deposit" },
      {
        first: `5`,
        more: "+",
        second: "Funding Methods",
      },
      { first: "140", more: "+", second: "Tradable Instruments" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "FX Leverage",
      },
      { symb: "<", first: "90", second: "MS Execution Speed" },
    ],
    security: "Security",
    securityDesc: `<span class="fw-bold">TrioMarkets™</span> gives great importance to the security of funds, <br /> considering the safety of its clients’ money as a top priority.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `A website secured by <br> SSL protocol`,
      },
      {
        img: `seg`,
        desc: `Clients Segregated <br> Funds`,
      },
    ],
    swissQuote: `Swissquote is a Swiss banking group specialising in the provision of online <br /> financial banking and trading services. The Group’s shares have been listed <br /> on the SIX Swiss Exchange since 29 May 2000.`,
    choosePlatform: "Choose Platform",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "Mobile Trading",
      },
      {
        img: "mam",
        name: "MAM/PAMMMAM Trading",
      },
    ],
    social: [
      {
        copying: "Copying",
        copyingPoints: [
          "Automated Environment",
          "Profitable Traders",
          "Easy to Use",
        ],
        trader: "Trader",
        traderPoints: ["Signal Trader", "Risk Managment", "Higher Pay-Out"],
      },
    ],
    keyFeatures: "Key Features",
    trioXtend: [
      {
        xtendText:
          "MT4 Trio Xtend is a suite of market-informed apps created for TrioMarkets™ traders to optimize their ability to trade successfully.<br>Offering traders cutting-edge tools as an extension to the MT4 trading platform, MT4 TrioXtend gives both novice and experienced traders the unique opportunity to trade as professionals.",
        xtendPoints: [
          [
            {
              img: "01",
              text: "The perfect personal trading assistant, featuring event based alerts on trade activity, market news, price action, and has the ability to perform automated actions, such as sending emails, SMS, or opening trades.",
            },
            {
              img: "02",
              text: "Offering traders the ability to connect to news, economic calendar, live signals and to get educated all from the MT4 platform.",
            },
            {
              img: "03",
              text: "Compares the correlation between two assets, providing the opportunity for quick trade execution based on real-time comparisons between markets.",
            },
            {
              img: "04",
              text: "Provides traders with valuable insight into market information on the correlation between assets in different markets, allowing traders to quickly assess their market exposure risk.",
            },
            {
              img: "05",
              text: "Powerful analysis tool in Excel based on real-time market data, enabling traders to monitor, evaluate, and report data directly from the MT4.",
            },
            {
              img: "06",
              text: "Provides full control over market watch prices, and all trading account activity. Traders can manage their account, orders and executions from one convenient window.",
            },
          ],
          [
            {
              img: "07",
              text: "Create pending and market order templates for ultra-fast execution. The Mini Terminal is a stand-alone smaller version of the Trade Terminal, focusing on a single market.",
            },
            {
              img: "08",
              text: "Offers live sentiment data across markets, enabling traders to be updated real-time on market sentiment and trade more effectively. The app also shows historic sentiment stats alongside market prices.",
            },
            {
              img: "09",
              text: "hows live and pending trading session times across the world map indicating key markets and peak liquidity times and markers for future news announcements.",
            },
            {
              img: "10",
              text: "Provides traders the opportunity to place hidden pending orders, stop losses and take profits, which will only appear in their MT4 account when actual market execution occurs.",
            },
            {
              img: "11",
              text: "Displays a variety of different tick charts and boasts a one-click trading functionality allowing traders the opportunity to execute short-term trades swiftly and precisely. This tool is perfect for scalpers.",
            },
            {
              img: "12",
              text: "A fully functional, all-in-one app offering traders speedy order execution and position management. It features, among others advanced stop-loss and take profit components, advanced close-all functions, in-depth order calculations (pip value, size), order templates, and OCO orders.",
            },
            {
              img: "13",
              text: "An Indicator package filled with highly sought after chart indicators, featuring Renko, Order History, Pivot, Chart Group, Candle Countdown, Freehand Drawing and many more.",
            },
          ],
        ],
      },
    ],
    seminar: {
      title: "Get to know us and what we can get to do together",
      points: [
        `<div><strong class="text-primary fs-4 fw-bolder">3:45pm </strong></div><span class="fs-6">Registration</span>`,
        `<div><strong class="text-primary fs-4 fw-bolder">4:15pm </strong></div><span class="fs-6">Intro to Forex & Intro to Triomarkets</span>`,
        `<div><strong class="text-primary fs-4 fw-bolder">4:30pm </strong></div><spa class="fs-6"n>Live trading with Head of Brokerage Mauro Mazzaco (T & Cs) will apply</span>`,
        `<div><strong class="text-primary fs-4 fw-bolder">6-6:15pm </strong/></div><span class="fs-6">Coffee Break, Mingling</span>`,
        `<div><strong class="text-primary fs-4 fw-bolder">6:15/7:00pm </strong/></div><span class="fs-6">Q & As</span>`,
      ],
      footer: `<strong class="fs-6 fw-bold">Fun session- Great Surprises  - Survey to wrap it up</strong>`,
    },
    riskWarning: (ctx) =>
      `Invest responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.${
        ctx.named("license") === "global"
          ? ""
          : `<br /> ${risk} of retail investor accounts lose money when trading CFDs with this provider.`
      } You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
  },
  fr: {
    title: {
      eu: `Découvrez <strong>TrioMarkets&trade;</strong>`,
      com: "Découvrez <strong>TrioMarkets&trade;</strong>",
      social: "Rejoignez la révolution du Trading Social!",
      xtend: "Découvrez TrioXtend exclusivement avec TrioMarkets&trade;",
    },
    points: {
      eu: [
        "Rejoignez un coutier ECN leader en forrex",
        "Licence pour le conseil en investissement et la gestion d'actifs",
        "Regulé en Europe",
        "Spreads ultra sérrés",
      ],
      com: [
        "Rejoignez un coutier ECN leader en forrex",
        "Licence pour le conseil en investissement",
        "Régulé par la FSCM (Financial Services Comission of Mauritius)",
        "Spreads ultra sérrés",
      ],
      social: [
        "Tradez en toute confiance dans un environnement rentable et automatisé",
        "Choisissez les traders les plus rentables et copiez leurs stratégies",
        "Faites partie d'une communauté commerciale de confiance et multipliez vos chances de succès",
      ],
      xtend: [
        "FXBlue – EA personnalisé / indicateurs",
        "Outils de gestion innovants",
        "Rejoignez un véritable Broker ECN",
        "Spreads Ultra Serrés",
      ],
    },
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min. Dépôt" },
      {
        first: `5`,
        more: "+",
        second: "Méthodes de Dépôts",
      },
      { first: "140", more: "+", second: "Produits de Trading" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Levier FX",
      },
      { symb: "<", first: "90", second: "MS Vitesse d'Execution" },
    ],

    security: "Sécurité",
    securityDesc: `<span class="fw-bold">TrioMarkets™</span> accorde une grande importance à la sécurité des fonds, considérant <br /> la sécurité de l’argent de ses clients comme une priorité absolue.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `Un site web sécurisé <br> par protocole SSL`,
      },
      {
        img: `seg`,
        desc: `Ségrégation des <br> fonds des clients`,
      },
    ],
    swissQuote: `Swissquote est un groupe bancaire suisse spécialisé dans la <br> fourniture de services bancaires et financiers. Les actions <br >du Groupe sont cotées à la SIX Swiss Exchange depuis le 29 mai 2000.`,
    choosePlatform: "Choisir une plateforme",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "Mobile Trading",
      },
      {
        img: "mam",
        name: "MAM/PAMMMAM Trading",
      },
    ],

    social: [
      {
        copying: "Copier",
        copyingPoints: [
          "Environnement automatisé",
          "Des commerçants rentables",
          "Facile à utiliser",
        ],
        trader: "Commerçant",
        traderPoints: [
          "Commerçant de signaux",
          "Gestion des risques",
          "Paiement plus élevé",
        ],
      },
    ],

    joinSocial: "Découvrez TrioXtend exclusivement avec TrioMarkets&trade;",
    joinSocialPoints: [
      "FXBlue – EA personnalisé / indicateurs",
      "Outils de gestion innovants",
      "Rejoignez un véritable Broker ECN",
      "Spreads Ultra Serrés",
    ],
    keyFeatures: "Caractéristiques Principales",
    trioXtend: [
      {
        xtendText:
          "MT4 Trio Xtend è una suite di applicazioni collegate al mercato create per i trader di TrioMarket per ottimizzare con successo il loro trading.<br>Strumenti all'avanguardia ad estensione della piattaforma di trading MT4. MT4 Trio Xtend offre sia ai trader principianti che agli esperti l'opportunità unica di operare come professionisti.",
        xtendPoints: [
          [
            {
              img: "01",
              text: "Il perfetto assistente personale di trading con avvisi in base a eventI sulle attività di trading , notizie di mercato, azione dei prezzi. Ha la capacità di effettuare azioni automatizzate, come ad esempio l'invio di email, SMS o compravendite di apertura.",
            },
            {
              img: "02",
              text: "Offre ai trader la possibilità di connettersi a notizie, calendario economico, segnali dal vivo e ottenere istruzioni su tutto dalla piattaforma MT4.",
            },
            {
              img: "03",
              text: "Confronta la correlazione tra due asset fornendo la possibilità di una rapida esecuzione del trading basata sul confronto in tempo reale tra i mercati.",
            },
            {
              img: "04",
              text: "Fornisce ai trader le informazioni di mercato sulla correlazione tra asset in mercati diversi, consentendogli di valutare rapidamente il rischio di esposizione sul mercato.",
            },
            {
              img: "05",
              text: "Potente strumento di analisi in Excel basato sui dati di mercato in tempo reale che consente ai trader di monitorare, valutare e riportare i dati direttamente dalla MT4.",
            },
            {
              img: "06",
              text: "Fornisce il pieno controllo sui prezzi di mercato e su tutte le attività del conto di trading. I trader possono gestire il loro conto, ordini e esecuzioni da una comoda finestra.",
            },
          ],
          [
            {
              img: "07",
              text: "Create pending and market order templates for ultra-fast execution. The Mini Terminal is a stand-alone smaller version of the Trade Terminal, focusing on a single market.",
            },
            {
              img: "08",
              text: "Offers live sentiment data across markets, enabling traders to be updated real-time on market sentiment and trade more effectively. The app also shows historic sentiment stats alongside market prices.",
            },
            {
              img: "09",
              text: "hows live and pending trading session times across the world map indicating key markets and peak liquidity times and markers for future news announcements.",
            },
            {
              img: "10",
              text: "Provides traders the opportunity to place hidden pending orders, stop losses and take profits, which will only appear in their MT4 account when actual market execution occurs.",
            },
            {
              img: "11",
              text: "Displays a variety of different tick charts and boasts a one-click trading functionality allowing traders the opportunity to execute short-term trades swiftly and precisely. This tool is perfect for scalpers.",
            },
            {
              img: "12",
              text: "A fully functional, all-in-one app offering traders speedy order execution and position management. It features, among others advanced stop-loss and take profit components, advanced close-all functions, in-depth order calculations (pip value, size), order templates, and OCO orders.",
            },
            {
              img: "13",
              text: "An Indicator package filled with highly sought after chart indicators, featuring Renko, Order History, Pivot, Chart Group, Candle Countdown, Freehand Drawing and many more.",
            },
          ],
        ],
      },
    ],
  },
  it: {
    title: {
      eu: `Scopri <strong>TrioMarkets™</strong>`,
      com: "Scopri <strong>TrioMarkets™</strong>",
      social: "Unisciti alla rivoluzione del Social Trading!",
      xtend: "Scopri TrioXtend esclusivamente con TrioMarkets&trade;",
    },
    points: {
      eu: [
        "Entra in un vero broker Forex ECN leader globale",
        "Licenza di Consulenza sugli Investimenti & Gestione del Portfolio",
        "Completamente regolamentato in UE",
        "Sperad Ultra Bassi",
      ],
      com: [
        "Entra in un vero broker Forex ECN leader globale",
        "Licenza di Consulenza sugli Investimenti",
        "Regolamentato dalla Financial Services Commission of Mauritius",
        "Sperad Ultra Bassi",
      ],
      social: [
        "Fai trading con fiducia in un ambiente redditizio e automatizzato",
        "Scegli i trader più redditizi e copia le loro strategie",
        "Entra a far parte di una community di trading affidabile e moltiplica le tue possibilità di successo",
      ],
      xtend: [
        "Indicatori/EA personalizzati FXBlue",
        "Strumenti di gestione innovativi",
        "Unisciti a un broker Forex leader di Global vero ECN",
        "Spread ultra stretti",
      ],
    },
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Dep minimo" },
      {
        first: `5`,
        more: "+",
        second: "Metodi di Depositi",
      },
      { first: "140", more: "+", second: "Prodotti di trading" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Leva FX",
      },
      { symb: "<", first: "90", second: "Velocita' di Esecuzione" },
    ],

    security: "Sicurezza",
    securityDesc: `<span class="fw-bold">TrioMarkets™</span> da grande importanza alla sicurezza dei fondi, <br>considerando la sicurezza del denaro dei suoi clienti come la prima priorità.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `Sito web protetto <br> dal protocollo SSL`,
      },
      {
        img: `seg`,
        desc: `Fondi dei <br> clienti separati`,
      },
    ],
    swissQuote: `Swissquote è un gruppo bancario svizzero specializzato <br> nell'offerta di servizi bancari e di trading online. Le azioni del gruppo <br> sono quotate nella SIX Swiss Exchange dal 29 maggio 2000.`,

    choosePlatform: "Scegli una Piattaforma",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "Mobile Trading",
      },
      {
        img: "mam",
        name: "MAM/PAMMMAM Trading",
      },
    ],

    social: [
      {
        copying: "Copiare",
        copyingPoints: [
          "Ambiente automatizzato",
          "Commercianti redditizi",
          "Facile da usare",
        ],
        trader: "Kopieren",
        traderPoints: [
          "Automatisierte Umgebung",
          "Profitable Händler",
          "Einfach zu verwenden",
        ],
      },
    ],

    joinSocial: "Scopri TrioXtend esclusivamente con TrioMarkets&trade;",
    joinSocialPoints: [
      "Indicatori/EA personalizzati FXBlue",
      "Strumenti di gestione innovativi",
      "Unisciti a un broker Forex leader di Global vero ECN",
      "Spread ultra stretti",
    ],
    keyFeatures: "Caratteristiche Principali",
    trioXtend: [
      {
        xtendText:
          "MT4 Trio Xtend è una suite di applicazioni collegate al mercato create per i trader di TrioMarket per ottimizzare con successo il loro trading.<br>Strumenti all'avanguardia ad estensione della piattaforma di trading MT4. MT4 Trio Xtend offre sia ai trader principianti che agli esperti l'opportunità unica di operare come professionisti.",
        xtendPoints: [
          [
            {
              img: "01",
              text: "Il perfetto assistente personale di trading con avvisi in base a eventI sulle attività di trading , notizie di mercato, azione dei prezzi. Ha la capacità di effettuare azioni automatizzate, come ad esempio l'invio di email, SMS o compravendite di apertura.",
            },
            {
              img: "02",
              text: "Offre ai trader la possibilità di connettersi a notizie, calendario economico, segnali dal vivo e ottenere istruzioni su tutto dalla piattaforma MT4.",
            },
            {
              img: "03",
              text: "Confronta la correlazione tra due asset fornendo la possibilità di una rapida esecuzione del trading basata sul confronto in tempo reale tra i mercati.",
            },
            {
              img: "04",
              text: "Fornisce ai trader le informazioni di mercato sulla correlazione tra asset in mercati diversi, consentendogli di valutare rapidamente il rischio di esposizione sul mercato.",
            },
            {
              img: "05",
              text: "Potente strumento di analisi in Excel basato sui dati di mercato in tempo reale che consente ai trader di monitorare, valutare e riportare i dati direttamente dalla MT4.",
            },
            {
              img: "06",
              text: "Fornisce il pieno controllo sui prezzi di mercato e su tutte le attività del conto di trading. I trader possono gestire il loro conto, ordini e esecuzioni da una comoda finestra.",
            },
          ],
          [
            {
              img: "07",
              text: "Create pending and market order templates for ultra-fast execution. The Mini Terminal is a stand-alone smaller version of the Trade Terminal, focusing on a single market.",
            },
            {
              img: "08",
              text: "Offers live sentiment data across markets, enabling traders to be updated real-time on market sentiment and trade more effectively. The app also shows historic sentiment stats alongside market prices.",
            },
            {
              img: "09",
              text: "hows live and pending trading session times across the world map indicating key markets and peak liquidity times and markers for future news announcements.",
            },
            {
              img: "10",
              text: "Provides traders the opportunity to place hidden pending orders, stop losses and take profits, which will only appear in their MT4 account when actual market execution occurs.",
            },
            {
              img: "11",
              text: "Displays a variety of different tick charts and boasts a one-click trading functionality allowing traders the opportunity to execute short-term trades swiftly and precisely. This tool is perfect for scalpers.",
            },
            {
              img: "12",
              text: "A fully functional, all-in-one app offering traders speedy order execution and position management. It features, among others advanced stop-loss and take profit components, advanced close-all functions, in-depth order calculations (pip value, size), order templates, and OCO orders.",
            },
            {
              img: "13",
              text: "An Indicator package filled with highly sought after chart indicators, featuring Renko, Order History, Pivot, Chart Group, Candle Countdown, Freehand Drawing and many more.",
            },
          ],
        ],
      },
    ],
  },
  de: {
    title: {
      eu: `Entdecken Sie TrioMarkets™`,
      com: "Entdecken Sie TrioMarkets™",
      social: "Schließen Sie sich der Social-Trading-Revolution an!",
    },
    points: {
      eu: [
        "Treten Sie einem Globalen True ECN Leading Forex Broker bei",
        "Lizenzierte Anlageberatung & Portfolioverwaltung",
        "Vollständig reguliert innerhalb der EU",
        "Ultra enge Spreads",
      ],
      com: [
        "Treten Sie einem Globalen True ECN Leading Forex Broker bei",
        "Lizenzierte Anlageberatung",
        "Reguliert von der Finanzdienstleistungskommission von Mauritius",
        "Ultra enge Spreads",
      ],
      social: [
        "Handeln Sie vertrauensvoll in einer profitablen und automatisierten Umgebung",
        "Wählen Sie die profitabelsten Trader und kopieren Sie ihre Strategien",
        "Werden Sie Teil einer vertrauenswürdigen Handelsgemeinschaft und vervielfachen Sie Ihre Erfolgschancen",
      ],
    },
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min. Einzahlung" },
      {
        first: `5`,
        more: "+",
        second: "Einzahlungsmethoden",
      },
      { first: "140", more: "+", second: "Handelsinstrumente " },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "FX Hebelverhältnis",
      },
      { symb: "<", first: "90", second: "MS Ausführungsgeschwindigkeit" },
    ],

    security: "Sicherheit",
    securityDesc: `<span class="fw-bold">TrioMarkets</span> legt großen Wert auf die Sicherheit vom Geld, <br> wobei die Sicherheit des Geldes seiner Kunden oberste Priorität hat.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `Eine durch das SSL-Protokoll <br> gesicherte Website`,
      },
      {
        img: `seg`,
        desc: `Von Kunden <br> getrennte Fonds`,
      },
    ],
    swissQuote: `Swissquote ist eine Schweizer Bankengruppe, die sich auf die Bereitstellung <br> von Online Finanzbanking und Handelsdienstleistungen spezialisiert hat. <br> Die Konzernaktien sind seit dem 29 Mai 2000 an der Schweizer Borse SIX notiert.`,

    choosePlatform: "Wähle eine Plattform",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "WebTrader",
      },
      {
        img: "mam",
        name: "MAM/PAMMMAM/PAMM",
      },
    ],
    social: [
      {
        copying: "Kopieren",
        copyingPoints: [
          "Automatisierte Umgebung",
          "Profitable Händler",
          "Einfach zu verwenden",
        ],
        trader: "Händler",
        traderPoints: [
          "Signalhändler",
          "Risikomanagement",
          "Höhere Auszahlung",
        ],
      },
    ],
  },
  ch: {
    title: {
      eu: `探索 TrioMarkets™`,
      com: "探索 TrioMarkets™",
      social: "加入社会交易革命！",
    },
    points: {
      eu: [
        "加入全球真正的ECN领先外汇经纪人",
        "持牌投资建议& 投资组合管理",
        "在欧盟内受到全面监管",
        "超紧点差",
      ],
      com: [
        "加入全球真正的ECN领先外汇经纪人",
        "持牌投资建议",
        "受毛里求斯金融服务委员会监管",
        "超紧点差",
      ],
      social: [
        "在有利可图的自动化环境中充满信心地进行交易",
        "选择最赚钱的交易者并复制他们的策略",
        "成为值得信赖的交易社区的一员，增加您成功的机会",
      ],
    },
    features: [
      { first: "0.0", second: "点差" },
      { symb: "$", first: "100", second: "最低存款" },
      { first: `5`, more: "+", second: "入金方式" },
      { first: "140", more: "+", second: "可交易工具" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "外汇杠杆",
      },
      { symb: "<", first: "90", second: "毫秒级执行速度" },
    ],
    security: "安全",
    securityDesc: `<span class="fw-bold">TrioMarkets™</span> 非常重视资金安全，将客户资金安全视为重中之重。`,
    bigIcons: [
      {
        img: "ssl",
        desc: `受 SSL 协议保护的网站`,
      },
      {
        img: `seg`,
        desc: `客户隔离基金`,
      },
    ],
    swissQuote: `Swissquote 是一家专门提供在线金融银行和交易服务的瑞士银行集团。<br /> 该集团的股票自 2000 年 5 月 29 日起在瑞士六大交易所上市。`,

    choosePlatform: "选择平台",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "网页交易",
      },
      {
        img: "mam",
        name: "MAM/PAMM",
      },
    ],
    social: [
      {
        copying: "复印",
        copyingPoints: ["自动化环境", "有利可图的交易者", "便于使用"],
        trader: "商人",
        traderPoints: ["信号交易者", "风险管理", "更高的支出"],
      },
    ],
  },
  es: {
    title: {
      eu: `Descubrir <strong>TrioMarkets™</strong>`,
      com: "Descubrir <strong>TrioMarkets™</strong>",
      social: "¡Únase a la revolución del Comercio Social!",
    },
    points: {
      eu: [
        "Únase a un bróker de Forex líder mundial en True ECN",
        "Asesoramiento en inversiones con licencia & Gestión de la cartera",
        "Completamente regulado dentro de la UE",
        "Spreads ultra ajustados",
      ],
      com: [
        "Únase a un bróker de Forex líder mundial en True ECN",
        "Asesoramiento en inversiones con licencia",
        "Regulado por la Comisión de Servicios Financieros de Mauricio",
        "Spreads ultra ajustados",
      ],
      social: [
        "Opere con confianza en un entorno rentable y automatizado",
        "Elige a los traders más rentables y copia sus estrategias",
        "Forme parte de una comunidad comercial confiable y multiplique sus posibilidades de éxito",
      ],
    },
    features: [
      { first: "0.0", second: "pips de Diferenciales" },
      { symb: "$", first: "100", second: "Depósito Mínimo" },
      {
        first: `5`,
        more: "+",
        second: "Métodos Depositos o Más",
      },
      { first: "140", more: "+", second: "Instrumentos de Trading o Más" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Apalancamiento",
      },
      { symb: "<", first: "90", second: "MS Velocidad de Ejecución" },
    ],

    security: "Seguridad",
    securityDesc: `<span class="fw-bold">TrioMarkets™</span> da gran importancia a la seguridad de los fondos, <br /> considerando la seguridad del dinero de sus clientes como una prioridad máxima.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `Un sitio web protegido por <br> protocolo SSL`,
      },
      {
        img: `seg`,
        desc: `Clientes Fondos <br>Segregados`,
      },
    ],
    swissQuote: `Swissquote es un grupo bancario suizo que se especializa en la prestación de <br />servicios bancarios financieros y comerciales en línea. Las acciones del Grupo cotizan <br /> en la SIX Swiss Exchange desde el 29 de mayo de 2000.`,

    choosePlatform: "Elija una Plataforma",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "Trading Móvil",
      },
      {
        img: "mam",
        name: "MAM/PAMM",
      },
    ],
    social: [
      {
        copying: "Proceso de copiar",
        copyingPoints: [
          "Entorno automatizado",
          "Comerciantes rentables",
          "Fácil de usar",
        ],
        trader: "Comerciante",
        traderPoints: [
          "Comerciante de señales",
          "Gestión de riesgos",
          "Pago más alto",
        ],
      },
    ],
  },
  vn: {
    title: {
      eu: `Khám phá <strong>TrioMarkets™</strong>`,
      com: "Khám phá <strong>TrioMarkets™</strong>",
      social: "Tham gia cuộc cách mạng Giao dịch xã hội!",
    },
    points: {
      eu: [
        "Tham gia Nhà môi giới ngoại hối hàng đầu ECN đích thực toàn cầu",
        "Tư vấn đầu tư được cấp phép & Quản lý danh mục đầu tư",
        "Được quy định đầy đủ trong EU",
        "Chênh lệch siêu chặt chẽ",
      ],
      com: [
        "Tham gia Nhà môi giới ngoại hối hàng đầu ECN đích thực toàn cầu",
        "Tư vấn đầu tư được cấp phép",
        "Được quy định bởi Ủy ban Dịch vụ Tài chính của Mauritius",
        "Chênh lệch siêu chặt chẽ",
      ],
      social: [
        "Giao dịch với sự tự tin trong một môi trường tự động và có lợi",
        "Chọn những nhà giao dịch có lợi nhất và sao chép chiến lược của họ",
        "Trở thành một phần của cộng đồng giao dịch đáng tin cậy và nhân lên cơ hội thành công của bạn",
      ],
    },
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Tiền gửi tối thiểu" },
      {
        first: `5`,
        more: "+",
        second: "Phương thức nạp tiền",
      },
      { first: "140", more: "+", second: "Công cụ có thể giao dịch" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Đòn bẩy FX",
      },
      { symb: "<", first: "90", second: "Tốc độ thực thi MS" },
    ],

    security: "Bảo vệ",
    securityDesc: `<span class = "fw-bold"> TrioMarkets ™ </span> coi trọng việc bảo mật tiền, <br /> coi sự an toàn cho tiền của khách hàng là ưu tiên hàng đầu.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `Một trang web được bảo mật bằng <br> giao thức SSL`,
      },
      {
        img: `seg`,
        desc: `Khách hàng được Tách biệt <br> Quỹ`,
      },
    ],
    swissQuote: `Swissquote là một tập đoàn ngân hàng của Thụy Sĩ chuyên cung cấp các dịch vụ giao dịch và ngân hàng tài chính <br /> trực tuyến. Cổ phiếu của Tập đoàn đã được niêm yết <br /> trên Sàn giao dịch Thụy Sĩ SIX kể từ ngày 29 tháng 5 năm 2000.`,

    choosePlatform: "Chọn một nền tảng",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "WebTrader",
      },
      {
        img: "mobile",
        name: "Giao dịch di động",
      },
      {
        img: "mam",
        name: "MAM/PAMM",
      },
    ],
    social: [
      {
        copying: "Sao chép",
        copyingPoints: [
          "Môi trường tự động",
          "Nhà giao dịch có lợi nhuận",
          "Dễ sử dụng",
        ],
        trader: "Người giao dịch",
        traderPoints: [
          "Nhà giao dịch tín hiệu",
          "Quản lý rủi ro",
          "Thanh toán cao hơn",
        ],
      },
    ],
  },
  arb: {
    title: {
      com: "اكتشف <strong>TrioMarkets™</strong>",
      social: "انضم إلى ثورة التداول الاجتماعي!",
      ramadan: "أطلق العنان لإمكانياتك المالية في رمضان، تداول مع تريوماركتس!",
    },
    points: {
      com: [
        "انضم إلى وسيط فوركس رائد عالميًا حقيقيًا بنظام ECN",
        "نصيحة مرخصة للاستثمار ",
        "نظمت من قبل لجنة الخدمات المالية في موريشيوس",
        "فروق أسعار ضيقة للغاية",
      ],
      social: [
        "تداول بثقة في بيئة آلية مربحة",
        "اختر المتداولين الأكثر ربحية وانسخ استراتيجياتهم",
        "كن جزءًا من مجتمع تجاري موثوق به وضاعف فرص نجاحك",
      ],
      ramadan: [
        "قم بإنشاء حساب حقيقي",
        " ضع الإيداع المطلوب",
        " احصل على %25 مكافأة ائتمانية",
      ],
    },
    features: [
      { first: "0.0", second: "هوامش النقاط" },
      { symb: "$", first: "100", second: "الحد الأدنى للإيداع" },
      {
        first: `5`,
        more: "+",
        second: "طرق التمويل",
      },
      { first: "140", more: "+", second: "الأدوات القابلة للتداول" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "الرافعة المالية في الفوركس",
      },
      { symb: "<", first: "90", second: "سرعة التنفيذ بجزء من الثانية" },
    ],
    security: "الأمن",
    securityDesc: `تعطي TrioMarkets ™ أهمية كبيرة لأمن الأموال ، <br /> مع مراعاة سلامة أموال عملائها كأولوية قصوى.`,
    bigIcons: [
      {
        img: "ssl",
        desc: `موقع مؤمن ببروتوكول <br> SSL`,
      },
      {
        img: `seg`,
        desc: `أموال العملاء  <br > المنفصلة`,
      },
    ],
    swissQuote: `Swissquote هي مجموعة مصرفية سويسرية متخصصة في تقديم <br /> الخدمات المصرفية المالية والتجارية عبر الإنترنت. تم إدراج أسهم المجموعة <br /> في بورصة SIX السويسرية منذ 29 مايو 2000.`,
    choosePlatform: "اختر منصة",
    icons: [
      {
        img: "mt4",
        name: "Meta Trader 4",
      },
      {
        img: "webtrader",
        name: "ويب تريدر",
      },
      {
        img: "mobile",
        name: "التداول عبر الجوال",
      },
      {
        img: "mam",
        name: "MAM/PAMM",
      },
    ],
    social: [
      {
        copying: "نسخ",
        copyingPoints: ["بيئة مؤتمتة", "التجار المربحون", "سهل الاستخدام"],
        trader: "تاجر",
        traderPoints: ["تاجر الإشارات", "إدارة المخاطر", "أرباح أعلى"],
      },
    ],
  },
};
