export default {
  en: {
    learnMore: `Learn More`,
    tradeNow: "Trade Now",
    openAccount: "Open Account",
    openLiveAccount: `Open Live Account`,
    createLiveAccount: `Create Live Account`
  },
  fr: {
    learnMore: `En Savoir Plus`,
    tradeNow: `Tradez maintenant`,
    openAccount: "Ouvrir un compte",
  },
  it: {
    learnMore: `Impara di Piu'`,
    tradeNow: "Trada Adesso",
    openAccount: "Apri Conto",
  },
  de: {
    learnMore: `Mehr erfahren`,
    openAccount: `Konto eröffnen`,
    tradeNow: `Jetzt traden`,
  },
  ch: { learnMore: `了解更多`, openAccount: `开立账户`, tradeNow: `立即交易` },
  es: {
    learnMore: `Aprende Más`,
    openAccount: `Abrir Cuenta`,
    tradeNow: `Negocia Ahora`,
  },
  vn: {
    learnMore: `Tìm hiểu thêm`,
    openAccount: `Mở tài khoản`,
    tradeNow: `Giao dịch ngay`,
  },
  arb: {
    learnMore: `تعرف على المزيد`,
    openAccount: `فتح حساب`,
    tradeNow: `تداول الآن`,
    openLiveAccount: `افتح حساب حقيقي`,
    createLiveAccount: `أنشئ حساب حقيقي`
  },
};
