<template>
  <div>
    <nav
      :id="scrolled < 200 ? '' : 'scrolled'"
      class="navbar navbar-expand-md navbar-dark fixed-top"
    >
      <div class="container-fluid">
        <a v-scroll-to="'#top'" class="">
          <img
            class="navbar-brand pointer m-0 h-100 ps-0"
            :src="`/img/${scrolled < 200 ? 'logo' : 'logo-dark'}.svg`"
            alt="TrioMarkets Logo"
          />
        </a>
        <div
          v-if="checkLanding !== 'ramadan'"
          class="collapse navbar-collapse"
          id="navbarCollapse"
        >
          <ul
            v-if="checkLanding !== 'seminar'"
            class="nav-items me-auto h-100 d-flex justify-content-center align-items-center m-0 p-0"
          >
            <li class="nav-item">
              <div class="tunnel"></div>
              <a
                href="#"
                v-scroll-to="{ el: scrllToLandingType, offset: setOffset }"
                class="nav-link"
                >{{ $t(`markets.${checkLanding}`) }}</a
              >
            </li>
            <li class="nav-item">
              <div class="tunnel"></div>
              <a href v-scroll-to="'#accounts'" class="nav-link">{{
                $t("accounts")
              }}</a>
            </li>
            <li class="nav-item">
              <div class="tunnel"></div>
              <a href v-scroll-to="'#licenses'" class="nav-link">{{
                $t("licenses")
              }}</a>
            </li>
            <li class="nav-item">
              <div class="tunnel"></div>
              <a href v-scroll-to="'#security'" class="nav-link">{{
                $t("security")
              }}</a>
            </li>
            <li class="nav-item">
              <div class="tunnel"></div>
              <a href v-scroll-to="'#platforms'" class="nav-link">{{
                $t("platforms")
              }}</a>
            </li>
          </ul>
          <ul
            v-else
            class="nav-items me-auto h-100 d-flex justify-content-center align-items-center m-0 p-0"
          >
            <li class="nav-item">
              <div class="tunnel"></div>
              <a
                href
                v-scroll-to="{ el: '#knowus', offset: -100 }"
                class="nav-link"
              >
                Know us
              </a>
            </li>
            <li class="nav-item">
              <div class="tunnel"></div>
              <a
                href
                v-scroll-to="{ el: '#speakers', offset: -50 }"
                class="nav-link"
              >
                Speakers
              </a>
            </li>
          </ul>
        </div>
        <div class="nav-right d-flex justify-space-between me-5">
          <div
            :class="`buttons-inline${
              scrolled < 200 ? '' : '-dark'
            } d-flex justify-content-center me-2`"
          >
            <a
              v-scroll-to="{
                el: '#top',
                onDone: onDone,
              }"
              href="#"
              :class="`btn btn-nav  ${checkArb ? 'ms-md-2' : 'me-md-2'}`"
              >{{ $t("register") }}</a
            >
            <a
              target="_blank"
              href="https://secure.triomarkets.com/en/login"
              class="btn btn-nav"
              >{{ $t("login") }}</a
            >
          </div>
          <Language v-if="checkLanding !== 'egypt'" />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { getters, mutations } from "../store";
import Language from "./Language.vue";
export default {
  name: "Navbar-Menu",
  data: () => ({
    scrolled: 0,
  }),
  components: {
    Language,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    checkLanding() {
      return getters.landingType()?.type;
    },
    checkArb() {
      return getters.checkArb();
    },
    scrllToLandingType() {
      if (this.checkLanding == "com" || this.checkLanding == "eu") {
        return "#homeMarkets";
      } else if (this.checkLanding == "social") {
        return "#social-section";
      } else {
        return "#trioXtend";
      }
    },
    setOffset() {
      if (this.checkLanding == "com" || this.checkLanding == "eu") {
        return -100;
      } else if (this.checkLanding == "social") {
        return -400;
      } else {
        return -600;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    onDone() {
      mutations.onDone();
    },
  },
};
</script>
<style lang="scss" scoped></style>
