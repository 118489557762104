<template>
  <div id="language" v-if="checkLanding !== 'seminar'">
    <a
      :class="`nav-link pointer d-flex justify-content-between  ${
        checkArb ? 'ps-0' : 'pe-0'
      }`"
      @click="showModal = true"
    >
      <div class="d-flex align-items-center">
        <img
          class="mx-1 align-text-bottom nav"
          :src="`/img/lang/${selectedLang}.svg`"
          :alt="selectedLang"
        />
        <span
          :class="`${
            (scrolled < 200 && !$route.meta.bg) || menu ? '' : 'dark'
          } ms-2 fs-6`"
          >{{ selectedLangName }}</span
        >
      </div>
    </a>
  </div>
  <div
    v-if="showModal"
    class="language-modal position-absolute row py-xl-3 ps-xl-3"
  >
    <div class="language-box">
      <div class="d-xl-none choose-lang py-5">
        <div class="d-flex align-items-center justify-content-between">
          <p class="fs-5 text-white fw-bold mb-0">
            {{ $t("selectYourLanguage") }}
          </p>
          <img
            @click="this.showModal = false"
            src="/img/navbar-mobile/menu-close.svg"
            alt="close-menu"
            class="close-land-choice"
          />
        </div>
      </div>
      <div class="row lang-row px-0">
        <div
          :class="[
            'lang col-xl-6',
            lang.code === selectedLang ? 'selected' : '',
          ]"
          v-for="(lang, id) in translationLanguages"
          :key="id"
          @click="changeLanguage(lang.code)"
        >
          <div class="d-flex language-tab py-2">
            <img
              class="d-block"
              :src="`/img/lang/${lang.code}.svg`"
              :alt="lang.name"
              @click="showModal = false"
            />
            <span
              :class="`mb-0  text-black text-white ${
                checkArb ? 'me-2 text-end' : 'ms-2'
              }`"
            >
              {{ lang.translateName }}
            </span>
          </div>
          <img
            v-if="lang.code === selectedLang"
            src="/img/navbar-mobile/tick.svg"
            alt="tick"
            class="d-xl-none tick"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "Language-Dropdown",
  data() {
    return {
      scrolled: 0,
      languages: [
        {
          name: "English",
          translateName: "English",
          code: "en",
        },
        {
          name: "French",
          translateName: "Français",
          code: "fr",
        },
        {
          name: "Italian",
          translateName: "Italiano",
          code: "it",
        },
        {
          name: "German",
          translateName: "Deutsch",
          code: "de",
        },
        {
          name: "Spanish",
          translateName: "Español",
          code: "es",
        },
        {
          name: "Chinese",
          translateName: "Chinese",
          code: "ch",
        },
        {
          name: "Vietnamese",
          translateName: "Tiếng Việt",
          code: "vn",
        },
        {
          name: "Arabic",
          translateName: "العربية",
          code: "arb",
        },
      ],
      showModal: false,
      selectedLangName: "English",
    };
  },

  computed: {
    license() {
      return getters.license();
    },
    translationLanguages() {
      if (this.checkLanding === "eu" && window.innerWidth > 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "arb" &&
            language.code !== this.selectedLang
          );
        });
      } else if (this.checkLanding === "eu" && window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "arb"
          );
        });
      } else if (this.checkLanding === "xtend" && window.innerWidth > 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "de" &&
            language.code !== "es" &&
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "arb" &&
            language.code !== this.selectedLang
          );
        });
      } else if (this.checkLanding === "xtend" && window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "de" &&
            language.code !== "es" &&
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "arb"
          );
        });
      } else if (this.checkLanding === "ramadan" && window.innerWidth > 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "de" &&
            language.code !== "es" &&
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "it" &&
            language.code !== "fr" &&
            language.code !== this.selectedLang
          );
        });
      } else if (this.checkLanding === "ramadan" && window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "de" &&
            language.code !== "es" &&
            language.code !== "ch" &&
            language.code !== "vn" &&
            language.code !== "it" &&
            language.code !== "fr"
          );
        });
      } else if (window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return language.code;
        });
      } else {
        return this.languages.filter((language) => {
          return language.code !== this.selectedLang;
        });
      }
    },
    selectedLang() {
      return getters.selectedLang();
    },
    checkArb() {
      return getters.checkArb();
    },
    checkLanding() {
      return getters.landingType()?.type;
    },
  },
  watch: {
    selectedLang: {
      handler(lang) {
        this.changeLanguage(lang);
      },
      immediate: true,
    },
  },
  methods: {
    changeLanguage(lang) {
      this.showModal = false;
      mutations.changeLanguage(lang);
      this.$root.$i18n.locale = lang;
      this.$router.replace({
        name: this.$route.name,
        params: { lang: lang },
        query: this.$route.query,
      });
      if (lang === "arb") {
        document.documentElement.setAttribute("dir", "rtl");
        document.body.style.fontFamily = "frutigerArb";
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        document.body.style.fontFamily = "Gilroy-Regular";
      }
      this.selectedLangName = this.languages.find(({ code }) => {
        return code === lang;
      }).translateName;
    },
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="scss"></style>
