<template>
  <div class="p-0" id="mainContainer">
    <Navbar />
    <Top />
    <div v-if="checkLanding == 'xtend'">
      <div v-for="(item, idx) in $tm('trioXtend')" :key="idx" id="trioXtend">
        <div class="row d-flex justify-content-center">
          <div class="col-sm-9 col-10">
            <p class="text-center fs-5 fw-bold" v-html="item.xtendText"></p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <h2 class="col-12 text-center title">
            {{ $t("keyFeatures") }}
          </h2>
          <hr class="blue" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10">
            <div class="row d-flex justify-content-center">
              <div
                v-for="(point, idx) in item.xtendPoints[0]"
                :key="idx"
                class="col-md-6 col-sm-10 col-11 d-flex align-items-center my-2"
              >
                <div class="forex-box xtend text-center">
                  <img
                    :src="`/img/xtend/${point.img}.svg`"
                    class="img-fluid icon my-2"
                    alt="icon"
                  />
                  <p class="mb-lg-0 fs-5 lh-sm" v-html="point.text"></p>
                </div>
              </div>
            </div>
            <div class="w-100 text-center">
              <a
                v-scroll-to="{
                  el: '#top',
                  onDone: onDone,
                }"
                class="btn btn-blue outline white py-2 mt-5"
                >{{ $t("learnMore") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checkLanding == 'social'">
      <div
        v-for="(item, idx) in $tm('social')"
        :key="idx"
        class="container-fluid my-5"
        id="social-section"
      >
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div
              class="col-xl-6 col-lg-5 d-flex align-items-center laptop-box p-0"
            >
              <img
                src="/img/social/laptop_new.png"
                alt=""
                class="img-fluid laptop"
              />
            </div>
            <div
              class="col-xl-6 col-lg-7 col-sm-12 col-10 position-relative py-3"
            >
              <div class="box w-50 ms-sm-4">
                <h4 class="fw-bold">{{ item.copying }}</h4>
                <div
                  v-for="(point, idx) in item.copyingPoints"
                  :key="idx"
                  class="point d-flex align-items-center mt-2"
                >
                  <img src="/img/check.svg" class="check me-2" alt="check" />
                  <p class="mb-0">{{ point }}</p>
                </div>
              </div>
              <div
                :class="`box behind ${
                  checkArb ? 'start-0 ms-4' : 'end-0'
                } position-absolute w-50`"
              >
                <h4 class="fw-bold">{{ item.trader }}</h4>
                <div
                  v-for="(point, idx) in item.traderPoints"
                  :key="idx"
                  class="point d-flex align-items-center mt-2"
                >
                  <img src="/img/check.svg" class="check me-2" alt="check" />
                  <p class="mb-0">{{ point }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checkLanding == 'ramadan'">
      <div class="container-fluid my-5">
        <div class="row d-flex justify-content-center">
          <h2
            class="col-xl-8 col-md-10 col-sm-11 text-center title fs-2 fw-bold mb-5"
            v-html="$t('ramadanTitile')"
          ></h2>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-xl-6 col-lg-7 col-md-10 col-sm-6 col-8">
            <div class="row">
              <div
                v-for="(point, idx) in $tm('ramadanPoints')"
                :key="idx"
                class="col-md-4"
              >
                <div
                  :class="`forex-box p-4 text-center mb-md-0 mb-4 ${
                    checkArb ? 'ramadan-box' : ''
                  }`"
                >
                  <p class="fw-bold text-center fs-5" v-html="point.name"></p>
                  <img
                    :src="`/img/ramadan/${point.img}.svg`"
                    class="icon"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid my-5">
        <div class="row d-flex justify-content-center">
          <h2
            class="col-xl-8 col-md-10 col-sm-11 text-center title fs-2 fw-bold"
            v-html="$t('ramadanFirstTable')"
          ></h2>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6 col-sm-8 col-10">
            <table width="100%" class="ramadanTable my-5">
              <thead>
                <tr>
                  <th class="fs-5">{{ $t("depositTable") }}</th>
                  <th class="fs-5">{{ $t("creditBonus") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(th, idx) in tableRamadan[0]" :key="idx">
                  <td class="fs-5">{{ th.d }}</td>
                  <td class="fs-5">{{ th.c }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <a class="btn btn-gold fs-4 mb-2 px-3 py-2" @click="onDone">{{
              $t("createLiveAccount")
            }}</a>
            <br />
            <a
              href="/FTD_Bonus_25.pdf"
              target="_blank"
              class="fw-bold text-trio fs-5"
              >{{ $t("termsConditions") }}</a
            >
          </div>
        </div>
      </div>
      <div class="container-fluid my-5">
        <div class="row d-flex justify-content-center">
          <h2
            class="col-xl-8 col-md-10 col-sm-11 text-center title fs-2 fw-bold"
            v-html="$t('ramadanSecondTable')"
          ></h2>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6 col-sm-8 col-10">
            <table width="100%" class="ramadanTable my-5">
              <thead>
                <tr>
                  <th class="fs-5">{{ $t("lotsTraded") }}</th>
                  <th class="fs-5">{{ $t("cashReward") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(th, idx) in tableRamadan[1]" :key="idx">
                  <td class="fs-5">{{ th.d }}</td>
                  <td class="fs-5">{{ th.c }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <a class="btn btn-gold fs-4 mb-2 px-3 py-2" @click="onDone">{{
              $t("createLiveAccount")
            }}</a>
            <br />
            <a
              href="/FTD_Bonus_25.pdf"
              target="_blank"
              class="fw-bold text-trio fs-5"
              >{{ $t("termsConditions") }}</a
            >
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <h2
            class="col-xl-8 col-md-10 col-sm-11 text-center title fs-2 fw-bold"
            v-html="$t('investingRamadan')"
          ></h2>
        </div>
      </div>
    </div>
    <HomeMarkets v-if="checkLanding == 'eu' || checkLanding == 'com'" />
    <Accounts v-if="checkLanding !== 'ramadan' && checkLanding !== 'seminar'" />
    <license
      id="licenses"
      v-if="checkLanding !== 'ramadan' && checkLanding !== 'seminar'"
    />
    <Security v-if="checkLanding !== 'ramadan' && checkLanding !== 'seminar'" />
    <Seminar v-if="checkLanding === 'seminar'" />
    <Speakers v-if="checkLanding === 'seminar'" />
    <Platforms
      id="platforms"
      v-if="checkLanding !== 'ramadan' && checkLanding !== 'seminar'"
    />
    <Footer />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import Navbar from "../components/Navbar";
import Top from "../components/Top";
import HomeMarkets from "../components/HomeMarkets";
import license from "../components/license";
import Security from "../components/Security";
import Platforms from "../components/Platforms";
import Accounts from "../components/Accounts.vue";
import Seminar from "../components/Seminar.vue";
import Speakers from "../components/Speakers.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Home-Page",
  data: () => ({
    tableRamadan: [
      [
        { d: "USD 500", c: "USD 125" },
        { d: "USD 1000", c: "USD 250" },
        { d: "USD 2000", c: "USD 500" },
        { d: "USD 3000", c: "USD 750" },
        { d: "USD 4000", c: "USD 1000" },
        { d: "USD 5000", c: "USD 1250" },
        { d: "SD 10.000", c: "USD 2500" },
      ],
      [
        { d: "12.5", c: "USD 125" },
        { d: "25", c: "USD 250" },
        { d: "50", c: "USD 500" },
        { d: "75", c: "USD 750" },
        { d: "100", c: "USD 1000" },
        { d: "125", c: "USD 1250" },
        { d: "250", c: "USD 2500" },
      ],
    ],
  }),
  components: {
    Navbar,
    Top,
    HomeMarkets,
    license,
    Security,
    Platforms,
    Accounts,
    Seminar,
    Speakers,
    Footer,
  },
  computed: {
    checkLanding() {
      return getters.landingType()?.type;
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  methods: {
    onDone() {
      mutations.onDone();
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
$trio: #00162c;
$primary: #007bff;
$primary-bootstrap: #007bff;
$dark-grey: #34363d;
$dark-faded: rgba(5, 28, 44, 0.199);
$trio-faded-shadow: #00162c44;
$gray-bg: #f2f3f4;
$light-gold: #ccba97;

#mainConatiner {
  overflow: hidden;
}

.ramadanTable {
  th,
  td,
  tr {
    text-align: center;
  }

  tr {
    &:nth-child(2n) {
      background: #f2f3f4;
    }
  }

  th {
    padding: 1.5rem;
    background: $trio;
    color: white;
    width: 50%;
  }

  td {
    padding: 0.5rem;
  }
}
</style>
