<template>
  <div id="security" class="d-flex flex-column justify-content-center align-items-center my-5">
    <div class="row w-100">
      <div class="col-md-12 d-flex flex-column align-items-center">
        <h2 class="title text-center">{{ $t("security") }}</h2>
        <hr class="blue" />
      </div>
    </div>
    <div class="row d-flex justify-content-center w-100">
      <div class="col-11 text-center">
        <p class="text-trio fs-5" v-html="$t('securityDesc')"></p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div
        class="col-sm-3 col-8 d-flex flex-column align-items-center"
        v-for="(icon, idx) in $tm('bigIcons')"
        :key="idx"
      >
        <img :src="`/img/${icon.img}.svg`" :alt="icon.desc" class="mb-3" />
        <p class="bold text-center text-trio fs-5" v-html="icon.desc"></p>
      </div>
    </div>
    <div class="row d-flex justify-content-center mb-5 pb-5">
      <div class="col-md-12 col-11">
        <div class="text-center p-4 forex-box security">
          <h3 class="fs-2 text-primary">Swissquote</h3>
          <p class="text-trio fs-5" v-html="$t('swissQuote')">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'Sec-Urity'
}
</script>
<style scoped lang="scss">
</style>