<template>
  <div class="container-fluid gray-box pb-5" id="footer">
    <div class="container d-none d-lg-block">
      <div class="row wire pt-5 pb-4">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <img
            v-for="(img, idx) in filterWire"
            :key="idx"
            :src="`/img/footer/${img.img}.svg`"
            :alt="img.name"
            class="img-fluid"
          />
        </div>
      </div>
      <hr />
    </div>
    <div class="container mt-4">
      <div class="row social">
        <div
          class="col-lg-3 col-12 d-flex align-items-center logo-col mt-lg-0 mt-4"
        >
          <img
            src="/img/logo-dark.svg"
            alt="TrioMarkets logo"
            class="img-fluid logo"
          />
        </div>
        <div
          v-if="!checkArb && checkLanding !== 'ramadan'"
          class="col-lg-3 col-12 d-flex align-items-center px-0 social-icons mt-lg-0 mt-4"
        >
          <a
            v-for="(icon, idx) in socials"
            :key="idx"
            :href="icon.link"
            target="_blank"
            class="social-icon d-flex flex-column p-0"
          >
            <img
              :src="`/img/footer/${icon.img}.svg`"
              :alt="icon.name"
              class="si-normal"
            />
            <img
              :src="`/img/footer/${icon.hoverImg}.svg`"
              :alt="icon.name"
              class="si-hover"
            />
          </a>
        </div>
        <div
          v-else
          class="col-lg-3 col-12 d-flex align-items-center px-0 social-icons mt-lg-0 mt-4"
        >
          <a
            v-for="(icon, idx) in socialsUae"
            :key="idx"
            :href="icon.link"
            target="_blank"
            class="social-icon d-flex flex-column p-0"
          >
            <img
              :src="`/img/footer/${icon.img}.svg`"
              :alt="icon.name"
              class="si-normal"
            />
            <img
              :src="`/img/footer/${icon.hoverImg}.svg`"
              :alt="icon.name"
              class="si-hover"
            />
          </a>
        </div>
        <div
          v-if="!checkArb && checkLanding !== 'ramadan'"
          class="col-lg-3 col-6 d-flex align-items-center justify-content-center phone"
        >
          <a
            class="pointer d-flex align-items-center ltr"
            :href="`tel:${
              license == 'global' ? '+44 20 376 936 49' : '+357 25 030685'
            }`"
            ><img
              class="me-xxl-3 me-2 my-lg-0 my-3 ms-1 footer-icon"
              src="/img/footer/handset.svg"
              alt="phone icon"
            />
            <span class="d-none d-sm-block">{{
              $t("contactPhone", { license: license })
            }}</span></a
          >
        </div>
        <div
          v-else
          class="col-lg-3 col-6 d-flex align-items-center justify-content-center phone"
        >
          <a
            class="pointer d-flex align-items-center ltr"
            href="tel:+971 4 242 1234"
            ><img
              class="me-xxl-3 me-2 my-lg-0 my-3 ms-1 footer-icon"
              src="/img/footer/handset.svg"
              alt="phone icon"
            />
            <span class="d-none d-sm-block">+971 4 242 1234</span></a
          >
        </div>
        <div
          class="col-lg-3 col-6 d-flex align-items-center justify-content-end email"
        >
          <a
            target="_blank"
            :href="
              license == 'global'
                ? 'mailto:info@triomarketsglobal.com'
                : 'mailto:info@triomarkets.com'
            "
            class="d-flex email align-items-center p-0 ltr"
          >
            <img
              class="me-xxl-3 me-2 my-lg-0 my-3 footer-icon ms-1"
              src="/img/footer/email.svg"
              alt="email icon"
            />
            <span class="d-none d-sm-block">{{
              license == "global"
                ? "info@triomarketsglobal.com"
                : "info@triomarkets.com"
            }}</span>
          </a>
        </div>
      </div>
      <hr class="mt-4" />
    </div>
    <div v-if="checkLanding !== 'ramadan'" class="container footer-menu mt-3">
      <div v-if="checkLanding !== 'seminar'" class="row desk-menu">
        <div class="col-12 d-flex pe-0 mb-4">
          <a
            href="#"
            v-scroll-to="{ el: '#trioXtend', offset: -600 }"
            class="nav-link fw-bold ps-0 text-trio"
            >{{ $t(`markets.${checkLanding}`) }}</a
          >
          <a
            href
            v-scroll-to="'#accounts'"
            class="nav-link text-trio fw-bold"
            >{{ $t("accounts") }}</a
          ><a
            href
            v-scroll-to="'#licenses'"
            class="nav-link text-trio fw-bold"
            >{{ $t("licenses") }}</a
          ><a
            href
            v-scroll-to="'#security'"
            class="nav-link text-trio fw-bold"
            >{{ $t("security") }}</a
          ><a
            href
            v-scroll-to="'#platforms'"
            class="nav-link text-trio fw-bold"
            >{{ $t("platforms") }}</a
          >
        </div>
      </div>
      <div v-else class="row desk-menu">
        <div class="col-12 d-flex pe-0 mb-4">
          <a
            href
            v-scroll-to="{ el: '#knowus', offset: -100 }"
            class="nav-link fw-bold ps-0 text-trio"
            >Know us</a
          ><a
            href
            v-scroll-to="{ el: '#speakers', offset: -50 }"
            class="nav-link text-trio fw-bold"
            >Speakers</a
          >
        </div>
      </div>
      <hr class="d-none d-md-block" />
    </div>
    <div v-if="license == 'eu'" class="container mt-5 mb-3 awards">
      <div class="row mb-5">
        <div class="col-12 text-center">
          <img src="/img/awards-lp.svg" class="img-fluid" alt="awards" />
        </div>
      </div>
      <hr />
    </div>
    <div class="container footer-bottom">
      <div class="d-flex pe-0 pe-lg-auto">
        <div>
          <a
            :href="`https://www.triomarkets.${
              checkLanding == 'eu' ? 'eu' : 'com'
            }/en/company/legal-documents`"
            class="mb-2 text-trio"
            >{{ $t("termsConditions") }}</a
          >
          |
          <a
            :href="`https://www.triomarkets.${
              checkLanding == 'eu' ? 'eu' : 'com'
            }/en/company/legal-documents`"
            class="mb-2 text-trio"
            >{{ $t("riskDisclosurePolicy") }}</a
          >
          |
          <a
            :href="`https://www.triomarkets.${
              checkLanding == 'eu' ? 'eu' : 'com'
            }/en/company/legal-documents`"
            class="text-trio"
            >{{ $t("privacyPolicy") }}</a
          >
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 pt-4">
          <p
            class="text-justify"
            v-html="$t('riskWarningFooter', { license: license })"
          ></p>
        </div>
        <div class="col-12 pt-3">
          <p
            class="text-justify mb-0"
            v-html="$t('triomarketsFooter', { license: license })"
          ></p>
        </div>
        <div v-if="license == 'eu'" class="col-12">
          <p class="text-justify" v-html="$t('edrDisclaimer')"></p>
        </div>
        <div v-if="license == 'global'" class="col-12">
          <p class="text-justify" v-html="$t('triomarketsDubai')"></p>
        </div>
        <div class="col-md-12 pt-2">
          <p
            class="text-justify mb-0"
            v-html="$t('riskWarning', { license: license })"
          ></p>
        </div>
        <div class="col-md-12">
          <p
            class="text-justify"
            v-html="$t('regionalRestrictions', { license: license })"
          ></p>
        </div>
        <div class="col-md-12 pt-2">
          <p class="text-justify mb-0">
            <strong>
              {{
                license == "global"
                  ? "BENOR CAPITAL LIMITED"
                  : "EDR FINANCIAL LIMITED"
              }}</strong
            >
            {{ $t("footerAddress", { license: license }) }}
          </p>
        </div>
        <div v-if="license == 'eu'" class="col-md-12">
          <p class="text-justify m-0" v-html="$t('footerTrademark')"></p>
        </div>
        <div class="col-md-12">
          <p class="text-justify">&copy;{{ $t("allRightsReserved") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import { getters } from "../store";
export default {
  name: "Footer-hui",
  data: () => ({
    email: "",
    currentSubMenuID: null,
    wire: [
      { img: "visa", name: "visa" },
      { img: "master", name: "mastercard" },
      { img: "wire", name: "wire transfer" },
      { img: "skrill", name: "skrill" },
      { img: "neteller", name: "neteller" },
      { img: "bit", name: "bitcoin" },
      { img: "perfectmoney", name: "perfectmoney" },
    ],
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/triomarkets",
        img: "fb",
        hoverImg: "fb-hover",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/TrioMarkets",
        img: "twitter",
        hoverImg: "twitter-hover",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/triomarkets/mycompany/",
        img: "linkedin",
        hoverImg: "linkedin-hover",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
        img: "youtube",
        hoverImg: "youtube-hover",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/triomarkets/",
        img: "insta",
        hoverImg: "insta-hover",
      },
    ],
    socialsUae: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/triomarketsmena",
        img: "fb",
        hoverImg: "fb-hover",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/TriomarketsMena",
        img: "twitter",
        hoverImg: "twitter-hover",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/triomarketsmena/",
        img: "linkedin",
        hoverImg: "linkedin-hover",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
        img: "youtube",
        hoverImg: "youtube-hover",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/triomarketsmena/",
        img: "insta",
        hoverImg: "insta-hover",
      },
      {
        name: "TikTok",
        link: "https://www.tiktok.com/@triomarketsmena",
        img: "tik-tok",
        hoverImg: "tik-tok-hover",
      },
    ],
  }),
  computed: {
    license() {
      return getters.license();
    },
    filterWire() {
      if (this.license === "global") {
        return this.wire.filter((img) => {
          return img.name !== "skrill";
        });
      } else if (this.license === "eu") {
        return this.wire.filter((img) => {
          return img.name !== "perfectmoney";
        });
      } else {
        return this.wire;
      }
    },
    // filterMenu() {
    //   return this.$tm("menuItems")
    //     .map((element) => {
    //       return element;
    //     })
    //     .map((el) => {
    //       let filterArray = el.submenu.filter((sub) => {
    //         if (this.license == "global") {
    //           return sub.license !== "eu";
    //         } else if (this.license == "eu") {
    //           return sub.license !== "global";
    //         } else {
    //           return sub.license;
    //         }
    //       });

    //       return {
    //         title: el.title,
    //         submenu: filterArray,
    //       };
    //     });
    // },
    checkArb() {
      return getters.checkArb();
    },
    textEnd() {
      if (this.checkArb) {
        return "text-end";
      } else {
        return "";
      }
    },
    checkLanding() {
      return getters.landingType()?.type;
    },
  },
};
</script>
<style lang="scss" scoped></style>
