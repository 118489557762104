<template>
  <div
    :class="`container-fluid top position-relative w-100 ${
      checkLanding == 'eu' || checkLanding == 'com' ? 'landing' : ''
    } ${checkLanding == 'social' ? 'landing-social' : ''} ${
      checkLanding == 'eu' ? 'euPadding' : ''
    } ${checkLanding == 'ramadan' ? 'landing-ramadan' : ''} ${
      checkLanding == 'seminar' ? 'landing-seminar' : ''
    }`"
    id="top"
  >
    <div class="row d-xl-none">
      <div class="col-sm-6 col-5 px-md-5 px-4 mt-4">
        <img src="/img/logo.svg" class="logo" alt="triomarkets logo" />
      </div>
      <div class="col-sm-6 col-7 px-md-5 px-4 mt-3 d-flex justify-content-end">
        <div>
          <Language />
        </div>
      </div>
    </div>
    <div class="row mt-5 mt-xl-0 mb-5">
      <div
        class="col-md-12 top-wrapper d-flex align-items-center justify-content-between"
      >
        <div class="heading px-md-3 px-4 w-100 mb-5">
          <h1
            :class="`mb-4 ${checkArb ? 'rtl' : ''}`"
            v-html="$t(`title.${checkLanding}`)"
          ></h1>
          <div class="heading-text">
            <div
              v-for="(point, idx) in $tm(`points.${checkLanding}`)"
              :key="idx"
              class="d-flex mt-2"
            >
              <img
                v-if="checkLanding !== 'seminar'"
                :class="`check ${checkArb ? 'rtl' : ''}`"
                :src="`/img/check${
                  checkLanding == 'ramadan' ? '-gold' : ''
                }.svg`"
                alt="Tick"
              />
              <p v-if="checkLanding !== 'seminar'" class="mb-1">{{ point }}</p>
              <p v-else class="mb-1" v-html="point"></p>
            </div>
          </div>
          <div v-if="checkLanding == 'eu'" class="mt-5">
            <img
              src="/img/awards-lp-dark.svg"
              class="w-100 pe-5 d-none d-sm-block"
              alt="awards"
            />
            <img
              src="/img/awards-ph-dark.svg"
              class="w-75 d-sm-none"
              alt="awards"
            />
          </div>
          <a
            v-scroll-to="{
              el: '#top',
              onDone: onDone,
            }"
            href="#"
            :class="`btn fs-5 mt-5 ${
              checkLanding == 'ramadan'
                ? 'btn-gold outline-gold mb-2'
                : 'btn-blue outline'
            }`"
            v-html="
              checkLanding == 'ramadan' ? $t('openLiveAccount') : $t('tradeNow')
            "
          ></a>
          <br v-if="checkLanding == 'ramadan'" />
          <a
            v-if="checkLanding == 'ramadan'"
            href="/FTD_Bonus_25.pdf"
            target="_blank"
            class="text-center text-white ms-5 fw-bold"
            >{{ $t("termsConditions") }}</a
          >
        </div>
        <Form id="regForm" />
      </div>
    </div>
    <div v-if="checkLanding == 'xtend'" class="w-100 text-center" id="xtend">
      <img
        src="/img/xtend/trioxtend.png"
        alt="xtend logo"
        class="img-fluid px-sm-0 px-2"
      />
    </div>
    <div
      v-if="checkLanding == 'eu' || checkLanding == 'com'"
      class="row features position-absolute bottom-0 start-0 m-0 w-100"
    >
      <div
        class="col-2 py-lg-3 py-2 px-lg-auto px-0"
        v-for="(feature, idx) in $tm('features')"
        :key="idx"
      >
        <p
          class="d-flex flex-column align-items-center justify-content-center text-center text-white fs-6 px-lg-1 lh-1 mb-0"
        >
          <span class="fs-2 mb-1"
            ><span class="text-gold">{{ feature.symb }}</span>
            <number
              ref="number1"
              :from="0"
              :to="feature.first"
              :format="theFormat"
              :duration="5"
              :delay="0"
              easing="Power1.easeOut"
              class="number-animation text-gold"
            /><span class="text-gold">{{ feature.more }}</span>
          </span>

          {{ feature.second }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import Language from "./Language.vue";
import Form from "./Form.vue";
export default {
  name: "Top-Section",
  components: {
    Form,
    Language,
  },
  computed: {
    checkLanding() {
      return getters.landingType()?.type;
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  methods: {
    onDone() {
      mutations.onDone();
    },
    theFormat(number) {
      if (number == "0.0") {
        return number.toFixed(1);
      } else {
        return number.toFixed(0);
      }
    },
    completed() {
      console.log("Animation ends!");
    },
    playAnimation() {
      this.$refs.number2.play();
    },
  },
};
</script>
<style lang="scss" scoped></style>
