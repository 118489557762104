let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "edr"
    : "benor";
export default {
  en: {
    accountTypes: "Account Types",
    leverage: `FX Leverage`,
    minDeposit: `Min. Deposit`,
    cfd: `CFD Leverage`,
    spread: `Min. Spread`,
    currency: `Account Currency`,
    wTime: `Withdrawal time`,
    commission: `Commission per side`,
    mcso: `Margin Call / Stop Out`,
    minVolume: `Min. Trade Volume`,
    deposit: `Deposit / Withdrawal`,
    standard: `Standard`,
    basic: `Basic`,
    commissions: [
      {
        name: "Basic <br> Account",
        desc: `This account is designed for traders who want to trade with zero commission starting with a minimum initial investment of $500. The Basic account is accessible for beginners who are looking to begin their trading journey with a friendly and transparent broker.`,
        leverage: `${license == "benor" ? "up to 1:500" : "up to 1:30"}`,
        minDeposit: "100 USD or equivalent",
        cfdLeverage: `${license == "benor" ? "up to 1:100" : "up to 1:20"}`,
        dw: `${
          license == "benor"
            ? "Wire Transfer, Credit Card, Neteller"
            : "Wire Transfer, Credit Card, Skrill, Neteller"
        }`,
        minSpread: "2.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISSION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Standard <br> Account",
        desc: `Keep it simple with TrioMarkets Standard account. Experience trading with low spreads starting from 1.4 pips per standard lot, with quote prices coming directly from our liquidity providers. Give yourself the advantage to trade with the world’s most popular trading platform – MetaTrader 4.`,
        leverage: `${license == "benor" ? "up to 1:500" : "up to 1:30"}`,
        minDeposit: "5000 USD or equivalent",
        cfdLeverage: `${license == "benor" ? "up to 1:100" : "up to 1:20"}`,
        dw: `${
          license == "benor"
            ? "Wire Transfer, Credit Card, Neteller"
            : "Wire Transfer, Credit Card, Skrill, Neteller"
        }`,
        minSpread: "1.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Advanced <br> Account",
        desc: `
          The Advanced account is suited for more experienced traders who want to trade with raw spread. Enjoy ultra-fast trading with an average execution speed of 32ms and absolutely no trading restrictions. All strategies and Expert Advisers are welcomed.`,
        leverage: `${license == "benor" ? "up to 1:500" : "up to 1:30"}`,
        minDeposit: "$ 25000 or equivalent",
        cfdLeverage: `${license == "benor" ? "up to 1:100" : "up to 1:20"}`,
        dw: `${
          license == "benor"
            ? "Wire Transfer, Credit Card, Neteller"
            : "Wire Transfer, Credit Card, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$5.5`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Premium <br> Account",
        desc: `Our Premium account offers you even better trading conditions with zero commission and only $4 per side per standard lot. Take advantage of the highest standard of service with a dedicated account manager to assist with your needs and requirements.`,
        leverage: `${license == "benor" ? "up to 1:500" : "up to 1:30"}`,
        minDeposit: "$ 50000 or equivalent",
        cfdLeverage: `${license == "benor" ? "up to 1:100" : "up to 1:20"}`,
        dw: `${
          license == "benor"
            ? "Wire Transfer, Credit Card, Neteller"
            : "Wire Transfer, Credit Card, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$4`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
  fr: {
    accountTypes: "Types de compte",
    leverage: `Effet de levier`,
    minDeposit: `Min. Dépôt`,
    cfd: `Effet de levier CFD`,
    spread: `Min. Spread`,
    currency: `Devise du compte`,
    wTime: `Délai de retrait`,
    commission: `Comimission par coté`,
    mcso: `Appel de marge / Stop Out`,
    minVolume: `Min. Volume des trades`,
    deposit: `Dépôt / Retrait`,
    standard: `Standard`,
    basic: `Basic`,
    commissions: [
      {
        name: "Basic <br <br>> Account",
        desc: `Ce compte est conçu pour les traders souhaitant négocier sans commission à partir d’un investissement initial minimum de 500$. Le compte Basic est accessible aux débutants qui souhaitent commencer à trader avec un courtier convivial et transparent.`,
        leverage: `${license == "benor" ? "Jusqu’à 1:500" : "Jusqu’à 1:30"}`,
        minDeposit: "100 USD ou équivalent",
        cfdLeverage: `${license == "benor" ? "Jusqu’à 1:100" : "Jusqu’à 1:20"}`,
        dw: `${
          license == "benor"
            ? "Virement bancaire, cartes de crédit, Neteller"
            : "Virement bancaire, cartes de crédit, Skrill, Neteller"
        }`,
        minSpread: "2.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISSION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Standard <br <br>> Account",
        desc: `Optez pour la simplicité avec le compte Standard TrioMarkets. Faites l’expérience du trading avec de faibles spreads débutant à 1,4 pip par lot standard, avec des prix de cotation provenant directement de nos fournisseurs de liquidité. Profitez de la plateforme de trading la plus populaire au monde – MetaTrader 4.`,
        leverage: `${license == "benor" ? "Jusqu’à 1:500" : "Jusqu’à 1:30"}`,
        minDeposit: "5000 USD ou équivalent",
        cfdLeverage: `${license == "benor" ? "Jusqu’à 1:100" : "Jusqu’à 1:20"}`,
        dw: `${
          license == "benor"
            ? "Virement bancaire, cartes de crédit, Neteller"
            : "Virement bancaire, cartes de crédit, Skrill, Neteller"
        }`,
        minSpread: "1.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Advanced <br <br>> Account",
        desc: `
        Le compte « Advanced » convient aux traders plus expérimentés souhaitant négocier avec des spreads bruts. Profitez d’un trading ultra-rapide avec une vitesse moyenne d’exécution de 32 ms et absolument aucune restriction de trading. Toutes les stratégies et Experts-Advisors sont les bienvenus.`,
        leverage: `${license == "benor" ? "Jusqu’à 1:500" : "Jusqu’à 1:30"}`,
        minDeposit: "$ 25000 ou équivalent",
        cfdLeverage: `${license == "benor" ? "Jusqu’à 1:100" : "Jusqu’à 1:20"}`,
        dw: `${
          license == "benor"
            ? "Virement bancaire, cartes de crédit, Neteller"
            : "Virement bancaire, cartes de crédit, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$5.5`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Premium <br <br>> Account",
        desc: `Notre compte Premium vous offre des conditions de trading encore meilleures sans commission et seulement 4 $ (x2) par lot standard. Bénéficiez du plus haut niveau de service avec un gestionnaire de compte professionnel dédié pour vous aider dans vos besoins et exigences.`,
        leverage: `${license == "benor" ? "Jusqu’à 1:500" : "Jusqu’à 1:30"}`,
        minDeposit: "$ 50000 ou équivalent",
        cfdLeverage: `${license == "benor" ? "Jusqu’à 1:100" : "Jusqu’à 1:20"}`,
        dw: `${
          license == "benor"
            ? "Virement bancaire, cartes de crédit, Neteller"
            : "Virement bancaire, cartes de crédit, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$4`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
  it: {
    accountTypes: "Tipi di conto",
    leverage: `Leva FX`,
    minDeposit: `Deposito min.`,
    cfd: `Leva CFD`,
    spread: `Spread min.`,
    currency: `Valuta conto`,
    wTime: `Tempistiche di Prelievo`,
    commission: `Commissione per parte`,
    mcso: `Margin Call / Stop Out`,
    minVolume: `Volume Min. Trade:`,
    deposit: `Deposito/Prelievo`,
    standard: `Standard`,
    basic: `Basic`,
    commissions: [
      {
        name: "Basic <br> Account",
        desc: `Questo conto è progettato per i trader che vogliono tradare con commissioni zero a partire da un investimento iniziale minimo di 500 $. Il conto Basic è indicato per i principianti che stanno cercando di iniziare il loro viaggio di trading con un broker affidabile e trasparente.`,
        leverage: `${license == "benor" ? "Fina a 1:500" : "Fina a 1:30"}`,
        minDeposit: "100 USD o equivalente",
        cfdLeverage: `${license == "benor" ? "Fina a 1:100" : "Fina a 1:20"}`,
        dw: `${
          license == "benor"
            ? "Bonifico, Carta di credito, Neteller"
            : "Bonifico, Carta di credito, Skrill, Neteller"
        }`,
        minSpread: "2.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISSION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Standard <br> Account",
        desc: `Mantieni la semplicità con il conto Standard di TrioMarkets. Esperienza di trading con spread bassi a partire da 1,4 pips per lotto standard, con prezzi di quotazione provenienti direttamente dai nostri liquidity providers. Concediti il vantaggio di fare trading con la piattaforma di trading più popolare al mondo: MetaTrader 4.`,
        leverage: `${license == "benor" ? "Fina a 1:500" : "Fina a 1:30"}`,
        minDeposit: "5000 USD o equivalente",
        cfdLeverage: `${license == "benor" ? "Fina a 1:100" : "Fina a 1:20"}`,
        dw: `${
          license == "benor"
            ? "Bonifico, Carta di credito, Neteller"
            : "Bonifico, Carta di credito, Skrill, Neteller"
        }`,
        minSpread: "1.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Advanced <br> Account",
        desc: `
        Il conto Advanced è adatto per i trader più esperti che desiderano operare con spread raw. Goditi un trading ultraveloce con una velocità di esecuzione media di 32 ms e assolutamente nessuna restrizione di trading. Tutte le strategie ed Expert Advisor sono i benvenuti.`,
        leverage: `${license == "benor" ? "Fina a 1:500" : "Fina a 1:30"}`,
        minDeposit: "$ 25000 o equivalente",
        cfdLeverage: `${license == "benor" ? "Fina a 1:100" : "Fina a 1:20"}`,
        dw: `${
          license == "benor"
            ? "Bonifico, Carta di credito, Neteller"
            : "Bonifico, Carta di credito, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$5.5`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Premium <br> Account",
        desc: `Il nostro conto Premium offre condizioni di trading ancora migliori con zero commissioni e solo 4 $ per parte per lotto standard. Approfitta dei più alti standard di servizio con un account manager dedicato per aiutarti con le tue esigenze e necessità.`,
        leverage: `${license == "benor" ? "Fina a 1:500" : "Fina a 1:30"}`,
        minDeposit: "$ 50000 o equivalente",
        cfdLeverage: `${license == "benor" ? "Fina a 1:100" : "Fina a 1:20"}`,
        dw: `${
          license == "benor"
            ? "Bonifico, Carta di credito, Neteller"
            : "Bonifico, Carta di credito, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$4`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
  de: {
    accountTypes: "Kontotypen",
    leverage: `Devisenhandels Hebel`,
    minDeposit: `Min. Eimzahlung`,
    cfd: `CFD Hebel`,
    spread: `Min. Spread`,
    currency: `Kontowährung`,
    wTime: `Rückbuchungsdauer`,
    commission: `Provision pro Seite`,
    mcso: `Margin Call/Stop Out`,
    minVolume: `Mindest. Handelsvolumen`,
    deposit: `Einzahlen/Rückbuchen`,
    standard: `Standard`,
    basic: `Basic`,
    commissions: [
      {
        name: "Basic <br> Account",
        desc: `Dieses Konto ist für Trader gedacht, die mit einer minimalen Anfangsinvestition von $500 kommissionsfrei handeln möchten. Das Basic-Konto ist für Anfänger geeignet, die ihre Trading Reise mit einem zuverlässigen und transparenten Broker beginnen möchten.`,
        leverage: `${license == "benor" ? "Bis zu 1:500" : "Bis zu 1:30"}`,
        minDeposit: "100 USD oder Äquivalent",
        cfdLeverage: `${license == "benor" ? "Bis zu 1:100" : "Bis zu 1:20"}`,
        dw: `${
          license == "benor"
            ? "Überweisung, Kreditkarten, Neteller"
            : "Überweisung, Kreditkarten, Skrill, Neteller"
        }`,
        minSpread: "2.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISSION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Standard <br> Account",
        desc: `Halten Sie es einfach mit dem TrioMarkets Standard-Konto. Erleben Sie das Traden mit niedrigen Spreads ab 1,4 Pips pro Standard-Lot, mit Kursen, die direkt von unseren Liquiditätsanbietern kommen. Gönnen Sie sich den Vorteil, mit der weltweit beliebtesten Tradingplattform MetaTrader 4 zu`,
        leverage: `${license == "benor" ? "Bis zu 1:500" : "Bis zu 1:30"}`,
        minDeposit: "5000 USD oder Äquivalent",
        cfdLeverage: `${license == "benor" ? "Bis zu 1:100" : "Bis zu 1:20"}`,
        dw: `${
          license == "benor"
            ? "Überweisung, Kreditkarten, Neteller"
            : "Überweisung, Kreditkarten, Skrill, Neteller"
        }`,
        minSpread: "1.4 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `NO COMMISION`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Advanced <br> Account",
        desc: `
        Das Advanced-Konto ist für erfahrenere Trader geeignet, die mit Raw Spread traden möchten. Genießen Sie ultraschnelles Traden mit einer durchschnittlichen Ausführungsgeschwindigkeit von 32ms und absolut keine Handelsbeschränkungen. Alle Strategien und Expert Advisers sind erlaubt.`,
        leverage: `${license == "benor" ? "Bis zu 1:500" : "Bis zu 1:30"}`,
        minDeposit: "$ 25000 oder Äquivalent",
        cfdLeverage: `${license == "benor" ? "Bis zu 1:100" : "Bis zu 1:20"}`,
        dw: `${
          license == "benor"
            ? "Überweisung, Kreditkarten, Neteller"
            : "Überweisung, Kreditkarten, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$5.5`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Premium <br> Account",
        desc: `Unser Premium-Konto bietet Ihnen noch bessere Trading Bedingungen mit null Kommission und nur $4 pro Seite und Standard-Lot. Genießen Sie den höchsten Servicestandard mit einem engagierten Account-Manager, der Sie bei Ihren Bedürfnissen und Anforderungen unterstützt.`,
        leverage: `${license == "benor" ? "Bis zu 1:500" : "Bis zu 1:30"}`,
        minDeposit: "$ 50000 oder Äquivalent",
        cfdLeverage: `${license == "benor" ? "Bis zu 1:100" : "Bis zu 1:20"}`,
        dw: `${
          license == "benor"
            ? "Überweisung, Kreditkarten, Neteller"
            : "Überweisung, Kreditkarten, Skrill, Neteller"
        }`,
        minSpread: "0.0 pips",
        accountCurrency: `EUR/USD/GPB/CHF`,
        commission: `$4`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
  ch: {
    accountTypes: "账户类型",
    leverage: `外汇杠杆`,
    minDeposit: `最低存款`,
    cfd: `差价合约杠杆`,
    spread: `最小 点差 `,
    currency: `账户币种`,
    wTime: `提款时间`,
    commission: `每边佣金`,
    mcso: `追加保证金/止损`,
    minVolume: `最小 交易量`,
    deposit: `存款/取款方式 `,
    standard: `标准`,
    basic: `基本`,
    commissions: [
      {
        name: "零佣金 <br> 基本",
        desc: `该账户专为希望以 500 美元的最低初始投资开始以零佣金进行交易的交易者而设计。希望通过友好透明的经纪人开始交易的初学者可以使用基本账户。`,
        leverage: `${license == "benor" ? "最多 1:500" : "最多 1:30"}`,
        minDeposit: "100 美元或等值",
        cfdLeverage: `${license == "benor" ? "最多 1:100" : "最多 1:20"}`,
        dw: `${
          license == "benor"
            ? "电汇、信用卡、Neteller"
            : "电汇、信用卡、Credit Card, Skrill, Neteller"
        }`,
        minSpread: "2.4 点数",
        accountCurrency: `欧元/美元/英镑/瑞士法郎`,
        commission: `无佣金`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "account",
        hover: "",
        img: "basic",
      },
      {
        name: "零佣金 <br> 标准",
        desc: `使用 TrioMarkets™ 标准账户保持简单。体验从每标准手 1.4 点开始的低点差交易，报价直接来自我们的流动性提供商。为自己提供与世界上最受欢迎的交易平台 MetaTrader 4 进行交易的优势。`,
        leverage: `${license == "benor" ? "最多 1:500" : "最多 1:30"}`,
        minDeposit: "5000 美元或等值",
        cfdLeverage: `${license == "benor" ? "最多 1:100" : "最多 1:20"}`,
        dw: `${
          license == "benor"
            ? "电汇、信用卡、Neteller"
            : "电汇、信用卡、Skrill, Neteller"
        }`,
        minSpread: "1.4 点数",
        accountCurrency: `欧元/美元/英镑/瑞士法郎`,
        commission: `无佣金`,
        marginCall: `200% / 50%`,
        stopOut: `50%`,
        minTradeVolume: "0.01 lot",
        class: "account",
        hover: "",
        img: "standart",
      },
      {
        name: "高级帐户",
        desc: `
        高级账户适合想要使用原始点差进行交易的更有经验的交易者。享受超快交易，平均执行速度为 32 毫秒，绝对没有交易限制。欢迎所有策略和专家顾问。`,
        leverage: `${license == "benor" ? "最多 1:500" : "最多 1:30"}`,
        minDeposit: "$ 25000 美元或等值",
        cfdLeverage: `${license == "benor" ? "最多 1:100" : "最多 1:20"}`,
        dw: `${
          license == "benor"
            ? "电汇、信用卡、 Neteller"
            : "电汇、信用卡、 Skrill, Neteller"
        }`,
        minSpread: "0.0 点数",
        accountCurrency: `欧元/美元/英镑/瑞士法郎`,
        commission: `$5.5`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 手",
        class: "account",
        hover: "",
        img: "advanced",
      },
      {
        name: "优质帐户",
        desc: `我们的优质账户为您提供更好的交易条件，零佣金，每标准手每边仅 4 美元。借助专门的客户经理，利用最高标准的服务来帮助满足您的需求和要求。`,
        leverage: `${license == "benor" ? "最多 1:500" : "最多 1:30"}`,
        minDeposit: "$ 50000 美元或等值",
        cfdLeverage: `${license == "benor" ? "最多 1:100" : "最多 1:20"}`,
        dw: `${
          license == "benor"
            ? "电汇、信用卡、 Neteller"
            : "电汇、信用卡、 Skrill, Neteller"
        }`,
        minSpread: "0.0 点数",
        accountCurrency: `欧元/美元/英镑/瑞士法郎`,
        commission: `$4`,
        marginCall: "200% / 50%",
        stopOut: `50%`,
        minTradeVolume: "0.01 手",
        class: "account",
        hover: "",
        img: "premium",
      },
    ],
  },
  es: {
    accountTypes: "Tipos de Cuenta",
    leverage: `Apalancamiento de Forex`,
    minDeposit: `Deposito min.`,
    spread: `Mín. Spread`,
    commission: `Comisión por side`,
    commissions: [
      {
        name: "Cuenta <br> Básica",
        desc: `Esta cuenta está diseñada para comerciantes que desean operar sin comisión a partir de una inversión inicial mínima de $250. La cuenta básica es accesible para principiantes que buscan comenzar su viaje comercial con un corredor amigable y transparente.`,
        leverage: `Hasta ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "100 USD o equivalente",
        minSpread: "2.4 pips",
        commission: `NO COMMISSION`,
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Cuenta <br> Estándar",
        desc: `Manténgalo simple con la cuenta estándar de TrioMarkets. Experimente el comercio con diferenciales bajos a partir de 1,4 pips por lote estándar, con precios de cotización provenientes directamente de nuestros proveedores de liquidez. Tenga la ventaja de operar con la plataforma de negociación más popular del mundo: MetaTrader 4.`,
        leverage: `Hasta ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "5000 USD o equivalente",
        minSpread: "1.4 pips",
        commission: `NO COMMISION`,
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Cuenta <br> Avanzada",
        desc: `La cuenta Avanzada es adecuada para operadores más experimentados que desean operar con diferenciales sin procesar. Disfrute de transacciones ultrarrápidas con una velocidad de ejecución promedio de 32 ms y absolutamente sin restricciones comerciales. Todas las estrategias y asesores expertos son bienvenidos.`,
        leverage: `Hasta ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "$ 25000 o equivalente",
        minSpread: "0.0 pips",
        commission: `$5.5`,
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Cuenta <br> Premium",
        desc: `Nuestra cuenta Premium le ofrece incluso mejores condiciones comerciales con cero comisiones y solo $ 4 por lado por lote estándar. Aproveche el más alto nivel de servicio con un administrador de cuenta dedicado para ayudarlo con sus necesidades y requisitos.`,
        leverage: `Hasta ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "$ 50000 o equivalente",
        minSpread: "0.0 pips",
        commission: `$4`,
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
  vn: {
    accountTypes: "Các loại tài khoản",
    leverage: `Đòn bẩy FX`,
    minDeposit: `Tiền nạp tối thiểu`,
    spread: `Spread Tối thiểu`,
    commission: `Hoa hồng mỗi bên`,
    commissions: [
      {
        name: "Tài khoản <br> cơ bản",
        desc: `Tài khoản này được thiết kế cho những nhà giao dịch muốn giao dịch với hoa hồng bằng 0 bắt đầu với khoản đầu tư ban đầu tối thiểu là 250 đô la. Tài khoản Cơ bản có thể truy cập được cho những người mới bắt đầu muốn bắt đầu hành trình giao dịch của mình với một nhà môi giới thân thiện và minh bạch. `,
        leverage: `lên đến ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "100 USD or equivalent",
        minSpread: "2,4 pips",
        commission: `KHÔNG CÓ HOA HỒNG`,
        class: "basic",
        hover: "",
        img: "basic",
      },
      {
        name: "Tài khoản <br> Chuẩn",
        desc: `Hãy đơn giản hóa nó với Tài khoản tiêu chuẩn của TrioMarkets. Trải nghiệm giao dịch với mức chênh lệch thấp bắt đầu từ 1,4 pips cho mỗi lô tiêu chuẩn, với giá báo giá đến trực tiếp từ các nhà cung cấp thanh khoản của chúng tôi. Hãy tạo cho mình lợi thế để giao dịch với nền tảng giao dịch phổ biến nhất thế giới - MetaTrader 4.`,
        leverage: `lên đến ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "5000 USD or equivalent",
        minSpread: "1,4 pips",
        commission: `KHÔNG CÓ HOA HỒNG`,
        class: "standart",
        hover: "",
        img: "standart",
      },
      {
        name: "Tài khoản <br> nâng cao",
        desc: `Tài khoản Nâng cao phù hợp dành cho các nhà giao dịch có kinh nghiệm hơn, những người muốn giao dịch với chênh lệch thô. Tận hưởng giao dịch cực nhanh với tốc độ khớp lệnh trung bình là 32ms và hoàn toàn không có giới hạn giao dịch. Tất cả các chiến lược và Cố vấn Chuyên gia đều được hoan nghênh.`,
        leverage: `lên đến ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "$ 25000 or equivalent",
        minSpread: "0,0 pips",
        commission: `$5.5`,
        class: "advanced",
        hover: "",
        img: "advanced",
      },
      {
        name: "Tài khoản <br> trả phí",
        desc: `Tài khoản Premium của chúng tôi cung cấp bạn thậm chí có điều kiện giao dịch tốt hơn với hoa hồng bằng không và chỉ $ 4 mỗi bên cho mỗi lô tiêu chuẩn. Tận dụng tiêu chuẩn dịch vụ cao nhất với người quản lý tài khoản chuyên dụng để hỗ trợ các nhu cầu và yêu cầu của bạn.`,
        leverage: `lên đến ${license == "global" ? "1:500" : "1:30"}`,
        minDeposit: "$ 50000 or equivalent",
        minSpread: "0,0 pips",
        commission: `$4`,
        class: "premium",
        hover: "",
        img: "premium",
      },
    ],
  },
};
