import navbar from "./navbar";
import form from "./form";
import home from "./home";
import markets from "./markets";
import tabs from "./tabs";
import footer from "./footer";
import license from "./license";
import accountTypes from "./accountTypes";
import callToAction from "./callToAction";
import ramadan from "./ramadan";
const sources = [
  navbar,
  form,
  home,
  tabs,
  footer,
  markets,
  license,
  accountTypes,
  callToAction,
  ramadan,
];
export default sources.reduce((acc, source) => {
  for (let key in source) {
    acc[key] = { ...(acc[key] || {}), ...source[key] };
  }
  return acc;
}, {});
