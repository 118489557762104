<template>
    <div id="knowus" class="d-flex flex-column justify-content-center align-items-center my-5">
        <div class="row w-100">
            <div class="col-md-12 d-flex flex-column align-items-center">
                <h2 class="title text-center">{{ $t("seminar.title") }}</h2>
                <hr class="blue" />
            </div>
        </div>
        <div class="row d-flex justify-content-center mb-5 pb-5">
            <div class="col-md-12 col-11">
                <div class="text-center p-4 forex-box seminar">
                    <div v-for="(point, idx) in $tm('seminar.points')" :key="idx" class="mt-4">
                        <p class="mb-1" v-html="point"></p>
                    </div>
                    <div class="mt-4" v-html="$t('seminar.footer')"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Sec-Urity'
}
</script>
<style scoped lang="scss"></style>