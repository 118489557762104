export default {
  en: {
    market: "Markets",
    marketsHome: [
      {
        name: "Shares",
        img: "shares",
        link: "/markets/shares",
        text: "Trade the major companies' stock prices on the world's leading markets.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ offers you 60+ of your favourite currency pairs. Trade Major, Minor & Exotic pairs.",
      },
      {
        name: "Indices",
        img: "indices",
        link: "/markets/indices",
        text: "Access the most popular Indices on MetaTrader 4 such as Nasdaq, S&P, Dow Jones and FTSE.",
      },
      {
        name: "Energies",
        img: "energies",
        link: "/markets/energies",
        text: "Get involved with the world's greatest demands; Natural Gas, Crude and Brent Oil.",
      },
      {
        name: "Cryptos",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Diversify you portfolio with Cryptocurrencies CFDs such as Bitcoin, Ethereum, Litecoin & XRP.",
      },
      {
        name: "Metals",
        img: "metals",
        link: "/markets/metals",
        text: "Get involved with the world's greatest demandes; Natural Gas, Crude and Brent Oil.",
      },
    ],
  },
  fr: {
    market: "Machés",
    marketsHome: [
      {
        name: "Actions",
        img: "shares",
        link: "/markets/shares",
        text: "Tradez les cours des actions des grandes entreprises sur les principaux marchés mondiaux.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ vous donne accès à plus de 60 de vos paires de devises préférées. Tradez des paires majeures, mineures et exotiques.",
      },
      {
        name: "Indices",
        img: "indices",
        link: "/markets/indices",
        text: "Accédez aux indices les plus populaires sur MetaTrader 4 tels que Nasdaq, S&P, Dow Jones et FTSE.",
      },
      {
        name: "Énergies",
        img: "energies",
        link: "/markets/energies",
        text: "Participez aux plus grandes demandes du monde; Gaz naturel, pétrole brut et Brent.",
      },
      {
        name: "Cryptos",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Diversifiez votre portefeuille avec des CFD sur crypto-monnaies tels que Bitcoin, Ethereum, Litecoin et XRP.",
      },
      {
        name: "Métaux",
        img: "metals",
        link: "/markets/metals",
        text: "Faites l'expérience du trading de métaux précieux comme l'or et l'argent, le palladium et le platine avec une vitesse d'exécution ultra-rapide.",
      },
    ],
  },
  it: {
    market: "Mercati",
    marketsHome: [
      {
        name: "Azioni",
        img: "shares",
        link: "/markets/shares",
        text: "Trada sui prezzi delle azioni delle maggiori Società nei principali mercati mondiali.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ ti offre 60+ delle tue coppie di valute preferite. Trada valute Major, Minor & Exotic.",
      },
      {
        name: "Indici",
        img: "indices",
        link: "/markets/indices",
        text: "Accedi ai principali Indici su MetaTrader 4 come Nasdaq, S&P, Dow Jones and FTSE.",
      },
      {
        name: "Energetici",
        img: "energies",
        link: "/markets/energies",
        text: "Partecipa alle materie prime più richieste del mondo; Gas naturale, Greggio e Petrolio Brent.",
      },
      {
        name: "Cripto",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Diversifica il tuo portfolio con CFD delle criptovalute come Bitcoin, Ethereum, Litecoin e XRP.",
      },
      {
        name: "Metalli",
        img: "metals",
        link: "/markets/metals",
        text: "Sperimenta il trading sui metalli prezioni come Oro & Argento, Palladio & Platino con una velocità istantanea di esecuzione.",
      },
    ],
  },
  ge: {
    market: "Märkte",
    marketsHome: [
      {
        name: "Aktien",
        img: "shares",
        link: "/markets/shares",
        text: "Handeln Sie mit den Aktienkursen der großen Unternehmen auf den weltweit führenden Märkten.",
      },
      {
        name: "Devisenhandel",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ bietet Ihnen mehr als 60 Ihrer bevorzugten Währungspaare. Handel Major, Minor & Exotic Paare.",
      },
      {
        name: "Indizes",
        img: "indices",
        link: "/markets/indices",
        text: "Greifen Sie auf die beliebtesten Indizes von MetaTrader 4 wie Nasdaq, S & P, Dow Jones und FTSE zu.",
      },
      {
        name: "Energie",
        img: "energies",
        link: "/markets/energies",
        text: "Beteiligen Sie sich an den größten Anforderungen der Welt. Erdgas, Rohöl und Brentöl.",
      },
      {
        name: "Kryptowährungen",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Diversifizieren Sie Ihr Portfolio mit Kryptowährungen-CFDs wie Bitcoin, Ethereum, Litecoin und XRP.",
      },
      {
        name: "Edelmetalle",
        img: "metals",
        link: "/markets/metals",
        text: "Erleben Sie den Handel mit Edelmetallen wie Gold & Silber, Palladium & Platin mit blitzschneller Ausführungsgeschwindigkeit.",
      },
    ],
  },
  ch: {
    market: "市场",
    marketsHome: [
      {
        name: "股票",
        img: "shares",
        link: "/markets/shares",
        text: "在世界主要市场上交易主要公司的股票价格。",
      },
      {
        name: "外汇",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™™ 为您提供 60 多种您最喜欢的货币对。",
      },
      {
        name: "指数",
        img: "indices",
        link: "/markets/indices",
        text: "接驳MetaTrader 4 上最受欢迎的指数，例如纳斯达克、标准普尔、道琼斯和富时指数。",
      },
      {
        name: "能量",
        img: "energies",
        link: "/markets/energies",
        text: "参与世界上最大的需求；天然气、原油和布伦特石油。",
      },
      {
        name: "加密货币",
        img: "crypto",
        link: "/markets/cryptos",
        text: "使用比特币、以太坊、莱特币和 XRP 等加密货币差价合约使您的投资组合多样化。",
      },
      {
        name: "金属",
        img: "metals",
        link: "/markets/metals",
        text: "体验以闪电般的执行速度交易黄金和白银、钯和铂等贵金属。",
      },
    ],
  },
  es: {
    market: "Mercados",
    marketsHome: [
      {
        name: "Comparte",
        img: "shares",
        link: "/markets/shares",
        text: "Opere los precios de las acciones de las principales empresas en los principales mercados del mundo.",
      },
      {
        name: "forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ le ofrece más de 60 de sus pares de divisas favoritos. Intercambie pares mayores, menores y exóticos.",
      },
      {
        name: "índices",
        img: "indices",
        link: "/markets/indices",
        text: "Acceda a los índices más populares en MetaTrader 4, como Nasdaq, S&P, Dow Jones y FTSE.",
      },
      {
        name: "energías",
        img: "energies",
        link: "/markets/energies",
        text: "Experimente el comercio de metales preciosos como oro y plata, paladio y platino con una velocidad de ejecución ultrarrápida.",
      },
      {
        name: "criptos",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Diversifique su cartera con CFD de criptomonedas como Bitcoin, Ethereum, Litecoin y XRP.",
      },
      {
        name: "rieles",
        img: "metals",
        link: "/markets/markets",
        text: "Involúcrate con las mayores demandas del mundo; Gas Natural, Crudo y Petróleo Brent.",
      },
    ],
  },
  vn: {
    market: "Thị trường",
    marketsHome: [
      {
        name: "chia sẻ",
        img: "shares",
        link: "/markets/shares",
        text: "Giao dịch giá cổ phiếu của các công ty lớn trên các thị trường hàng đầu thế giới.",
      },
      {
        name: "ngoại hối",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™™ cung cấp cho bạn hơn 60 cặp tiền tệ yêu thích của bạn. Các cặp giao dịch Chính, Nhỏ & Kỳ lạ.",
      },
      {
        name: "chỉ số",
        img: "indices",
        link: "/markets/indices",
        text: "Truy cập các Chỉ số phổ biến nhất trên MetaTrader 4 như Nasdaq, S&P, Dow Jones và FTSE.",
      },
      {
        name: "năng lượng",
        img: "energies",
        link: "/markets/energies",
        text: "Trải nghiệm giao dịch các kim loại quý như Vàng & Bạc, Palladium & Bạch kim với tốc độ khớp lệnh nhanh như chớp.",
      },
      {
        name: "tiền điện tử",
        img: "crypto",
        link: "/markets/cryptos",
        text: "Đa dạng hóa danh mục đầu tư của bạn với CFD tiền điện tử như Bitcoin, Ethereum, Litecoin & XRP.",
      },
      {
        name: "kim loại",
        img: "metals",
        link: "/markets/markets",
        text: "Tham gia với những nhu cầu lớn nhất của thế giới; Khí tự nhiên, dầu thô và dầu Brent.",
      },
    ],
  },
  arb:{
    market: "الأسواق",
    marketsHome: [
      {
        name: "الأسهم",
        img: "shares",
        link: "/markets/shares",
        text: "تداول على أسعار أسهم الشركات الكبرى في الأسواق الرائدة في العالم.",
      },
      {
        name: "فوركس",
        img: "forex",
        link: "/markets/forex",
        text: "تقدم لك TrioMarkets™ أكثر من 60 زوجًا من أزواج العملات المفضلة لديك. أزواج التجارة الرئيسية والصغرى والغريبة.",
      },
      {
        name: "المؤشرات",
        img: "indices",
        link: "/markets/indices",
        text: "قم بالوصول إلى المؤشرات الأكثر شيوعًا على MetaTrader 4 مثل Nasdaq و S&P و Dow Jones و FTSE.",
      },
      {
        name: "الطاقة",
        img: "energies",
        link: "/markets/energies",
        text: "شارك في أكبر مطالب العالم ، الغاز الطبيعي والنفط الخام ونفط برنت.",
      },
      {
        name: "العملات المشفرة",
        img: "crypto",
        link: "/markets/cryptos",
        text: "نوّع محفظتك باستخدام عقود الفروقات للعملات المشفرة مثل Bitcoin و Ethereum و Litecoin و XRP.",
      },
      {
        name: "المعادن",
        img: "metals",
        link: "/markets/metals",
        text: "جرب تداول المعادن الثمينة مثل الذهب والفضة والبلاديوم والبلاتينيوم بسرعة تنفيذ فائقة.",
      },
    ],
  }
};
