export default {
  en: {
    licensesRegulations: `Licenses & Regulations`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> is registered as an Investment Dealer (Broker) with registration number 160496. Licensed by the Financial Services Commission (Mauritius) under license number <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> and has the license to perform operations under the trading name TrioMarkets™.</p><p class="text-justify fs-6 mb-0">We are authorized to trade internationally, specializing in CFD online trading on currencies, commodities, indices, stocks and forward contracts. We are committed to the utmost in business standards and consider our dedication to ethics a cornerstone of our philosophy.</p>`,
        eu: `<strong>TrioMarkets™</strong> is a trading name 
          owned and operated by <strong>EDR Financial Ltd</strong> and is registered as a Cyprus Investment Firm (CIF) with the registration number <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">This <strong>ECN/STP</strong> Forex broker is regulated by the Cyprus Securities and Exchange commission <strong>(CySEC)</strong> under the license number <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, which falls under the umbrella of the <strong>Markets in Financial Instruments Directive II (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> is a representative office/Branch of Benor Capital Ltd. (Mauritius) located in the United Arab Emirates - Dubai under the DED License number<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Registered Office: The Bayswater Tower , Office 1212, Marasi Drive, Business Bay, Dubai, United Arab Emirates and has the license to perform marketing and commercial activities under the trading name TrioMarkets&trade;. `,
      },
    ],
  },
  fr: {
    licensesRegulations: `Licence & Régulations`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd </strong> è registrato come Investment Dealer (Broker) con numero di registrazione 160496. Autorizzato dalla Financial Services Commission (Mauritius) con numero di licenza <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> e ha la licenza per eseguire operazioni con il nome commerciale TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Siamo autorizzati a negoziare a livello internazionale, specializzandoci nel trading online di CFD su valute, materie prime, indici, azioni e contratti a termine. Ci impegniamo al massimo per quanto riguarda gli standard aziendali e consideriamo la nostra dedizione all'etica una pietra angolare della nostra filosofia.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™ </strong> è un nome commerciale di proprietà e gestito da <strong> EDR Financial Ltd </strong> ed è registrato come Cyprus Investment Firm (CIF) con il numero di registrazione <strong>(HE336081). </strong></p><p class="text-justify fs-6 mb-0">Questo broker Forex <span class="fw-bold">ECN/STP</span> è regolamentato dalla Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> con il numero di licenza <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, che rientra nell'ambito della <span class="fw-bold">Direttiva sui mercati degli strumenti finanziari II (MIFID II)</span>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> est un bureau de représentation/succursale de Benor Capital Ltd. (Maurice) situé aux Émirats Arabes Unis - Dubaï, sous le numéro de licence DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Siège social: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubaï, Émirats Arabes Unis, et détient la licence pour exercer des activités de marketing et commerciales sous le nom commercial TrioMarkets&trade;.`,
      },
    ],
  },
  it: {
    licensesRegulations: `Licenze & Regolamentazioni`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> è registrato come Investment Dealer (Broker) con numero di registrazione 160496. Autorizzato dalla Financial Services Commission (Mauritius) con numero di licenza <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> e ha la licenza per eseguire operazioni con il nome commerciale TrioMarkets™.</p><p class="text-justify  mb-0">Siamo autorizzati a negoziare a livello internazionale, specializzandoci nel trading online di CFD su valute, materie prime, indici, azioni e contratti a termine. Ci impegniamo al massimo per quanto riguarda gli standard aziendali e consideriamo la nostra dedizione all'etica una pietra angolare della nostra filosofia.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> è un nome commerciale di proprietà e gestito da EDR Financial Ltd ed è registrato come Cyprus Investment Firm (CIF) con il numero di registrazione <strong> (HE336081). </strong></p><p class="text-justify fs-6 mb-0">Questo broker Forex <span class="fw-bold">ECN/STP</span> è regolamentato dalla Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> con il numero di licenza <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, che rientra nell'ambito della <span class="fw-bold">Direttiva sui mercati degli strumenti finanziari II (MIFID II)(MIFID II).</span></p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> è un ufficio di rappresentanza/filiale di Benor Capital Ltd. (Mauritius) situato negli Emirati Arabi Uniti - Dubai con il numero di licenza DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Sede legale: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubai, Emirati Arabi Uniti e ha la licenza per svolgere attività di marketing e commerciali con il nome commerciale TrioMarkets&trade;.`,
      },
    ],
  },
  de: {
    licensesRegulations: `Lizenzen & Regulierungen`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> ist als Investment Dealer (Broker) mit der Registrierungsnummer 160496 registriert. Lizenziert von der Financial Services Commission (Mauritius) unter der Lizenznummer <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> und verfügt über die Lizenz zur Durchführung von Operationen unter dem Handelsnamen TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Wir sind für den internationalen Handel zugelassen und auf den CFD-Online-Handel mit Währungen, Rohstoffen, Indizes, Aktien und Terminkontrakten spezialisiert. Wir verpflichten uns zu den höchsten Geschäftsstandards und betrachten unser Engagement für Ethik als Eckpfeiler unserer Philosophie.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> ist ein Handelsname, der EDR Financial Ltd gehört und von <strong>EDR Financial Ltd </strong> betrieben wird und als zypriotische Investmentfirma (CIF) mit der Registrierungsnummer <strong> (HE336081). </strong> registriert ist.</p><p class="text-justify fs-6 mb-0">Dieser <span class="fw-bold">ECN/STP</span> Forex-Broker wird von der Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> unter der Lizenznummer <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a> reguliert, die unter das Haftungsdach der <span class="fw-bold">Markets in Financial Instruments Directive II (MIFID II) fällt.</span></p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> ist eine Repräsentanz/Zweigstelle von Benor Capital Ltd. (Mauritius) mit Sitz in den Vereinigten Arabischen Emiraten - Dubai unter der DED-Lizenznummer<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Eingetragener Sitz: The Bayswater Tower , Office 1212, Marasi Drive, Business Bay, Dubai, Vereinigte Arabische Emirate und hat die Lizenz, Marketing- und Handelsaktivitäten unter dem Handelsnamen TrioMarkets&trade; durchzuführen. `,
      },
    ],
  },
  ch: {
    licensesRegulations: `牌照与监管`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong>注册为投资交易商（经纪商），注册号为160496。获得金融服务委员会(毛里求斯)的许可,许可号为<a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a>，并有权以交易名称TrioMarkets™ 开展业务。</p><p class="text-justify fs-6 mb-0">我们被授权进行国际交易, 专门从事货币、商品、指数、股票和远期合约的差价合约在线交易。 我们致力于最高的商业标准, 并将我们对道德的奉献视为我们理念的基石。</p>`,
        dubai: `<strong>Benor capital Ltd-代表处</strong>是Benor Capital Ltd.(Mauritius)在阿拉伯联合酋长国迪拜的代表机构/分公司，DED许可证号为  <a target="_blank"> <strong class="text-primary">1082819</strong></a>。注册办事处：The Bayswater Tower，Office 1212，Marasi Drive，Business Bay，迪拜，阿拉伯联合酋长国，拥有以TrioMarkets™交易名称开展营销和商业活动的许可。`,
      },
    ],
  },
  es: {
    licensesRegulations: `Licencia y Reglamento`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> está registrada como agente de inversiones (corredor) con el número de registro 160496. Con licencia de la Comisión de Servicios Financieros (Mauricio) con el número de licencia <a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> y tiene la licencia para realizar operaciones con el nombre comercial TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Estamos autorizados para operar internacionalmente, y nos especializamos en el comercio en línea de CFD sobre divisas, materias primas, índices, acciones y contratos a plazo. Estamos comprometidos con los máximos estándares comerciales y consideramos que nuestra dedicación a la ética es la piedra angular de nuestra filosofía.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> es un nombre comercial que pertenece y es operado por <strong>EDR Financial Ltd</strong> y está registrado como una empresa de inversión de Chipre (CIF) con el número de registro <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">Este corredor de Forex <strong>ECN/STP</strong> está regulado por la Comisión de Bolsa y Valores de Chipre <strong>(CySEC)</strong> con el número de licencia <a  target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, que se encuentra bajo el paraguas de la Directiva de <strong>Mercados de Instrumentos Financieros II (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> es una oficina de representación/sucursal de Benor Capital Ltd. (Mauricio) situada en los Emiratos Árabes Unidos - Dubái con el número de licencia DED <a target="_blank"> <strong class="text-primary">1082819</strong></a>. Domicilio social: The Bayswater Tower , Oficina 1212, Marasi Drive, Business Bay, Dubai, Emiratos Árabes Unidos y tiene la licencia para realizar actividades comerciales y de marketing bajo el nombre comercial TrioMarkets&trade;.`,
      },
    ],
  },
  vn: {
    licensesRegulations: `Giấy phép & Quy định`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd </strong> được đăng ký là Đại lý Đầu tư (Nhà môi giới) với số đăng ký 160496. Được cấp phép bởi Ủy ban Dịch vụ Tài chính (Mauritius) theo giấy phép số <a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> và có giấy phép thực hiện các hoạt động dưới tên giao dịch TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Chúng tôi được phép giao dịch quốc tế, chuyên giao dịch trực tuyến CFD về tiền tệ, hàng hóa, chỉ số, cổ phiếu và hợp đồng kỳ hạn. Chúng tôi cam kết tuân thủ tối đa các tiêu chuẩn kinh doanh và coi sự cống hiến cho đạo đức là nền tảng trong triết lý của chúng tôi.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> là văn phòng đại diện / Chi nhánh của Benor Capital Ltd. (Mauritius) đặt tại Các Tiểu vương quốc Ả Rập Thống nhất - Dubai theo số Giấy phép DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Văn phòng đã đăng ký: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubai, United Arab Emirates và có giấy phép thực hiện các hoạt động tiếp thị và thương mại dưới tên giao dịch TrioMarkets&trade;.`,
      },
    ],
  },
  arb: {
    licensesRegulations: `التراخيص واللوائح`,
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> مسجلة بصفتها تاجر استثمار (وسيط) برقم تسجيل 160496. مرخصة من قبل لجنة الخدمات المالية (موريشيوس) بموجب ترخيص رقم  <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> ولديها ترخيص لإجراء العمليات تحت الاسم التجاري TrioMarkets™ ️.
        </p><p class="text-justify fs-6 mb-0">مصرح لنا بالتداول دوليًا ، متخصصون في تداول العقود مقابل الفروقات عبر الإنترنت على العملات والسلع والمؤشرات والأسهم والعقود الآجلة. نحن ملتزمون بأقصى معايير العمل ونعتبر تفانينا في الأخلاق حجر الزاوية في فلسفتنا.</p>`,
        dubai: `<strong>Benor Capital Ltd- Rep. Office</strong> هو مكتب تمثيلي / فرع لشركة Benor Capital Ltd. (موريشيوس) يقع في الإمارات العربية المتحدة - دبي بموجب ترخيص دائرة التنمية الاقتصادية رقم <a target="_blank"> <strong class="text-primary">1082819</strong></a>. المكتب المسجل: برج بايزووتر ، مكتب 1212 ، مراسي درايف ، الأعمال باي ، دبي ، الإمارات العربية المتحدة ولديها ترخيص لأداء الأنشطة التسويقية والتجارية تحت الاسم التجاري TrioMarkets™.`,
      },
    ],
  },
};
