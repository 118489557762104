import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import i18n from "./translations/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/app.scss";
import VueNumber from 'vue-number-animation'

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(VueNumber);
app.use(VueScrollTo, {
  container: "body",
  duration: 200,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
app.mount("#app");
