<template>
  <div class="container-fluid py-5" id="platforms">
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex flex-column align-items-center">
          <h2 class="title">{{ $t("choosePlatform") }}</h2>
          <hr class="mb-5 blue" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          v-for="(icon, idx) in $tm('icons')"
          :key="idx"
          class="col-lg-3 col-6 platform text-center mt-lg-0 mt-4"
        >
          <img
            :src="`/img/platforms/${icon.img}.svg`"
            class="icon"
            :alt="icon.name"
          />
          <p class="text-trio pt-3 fs-5">{{ icon.name }}</p>
          <a
            href="#"
            v-scroll-to="{
              el: '#top',
              onDone: onDone,
            }"
            class="btn transparent fs-6"
            >{{ $t("openAccount") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
export default {
  name: "Platforms-Choose",
  methods: {
    onDone() {
      mutations.onDone();
    },
  },
};
</script>
<style lang="scss" scoped></style>
