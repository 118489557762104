import messages from "./";
import { createI18n } from "vue-i18n/index";
let checkEgypt = false;
let isEgypt = window.location.href.split("/")[3];
switch (window.location.host) {
  case "localhost:8084":
    checkEgypt = false;
    break;
  case "landing.triomarkets.com":
    if (isEgypt === "ramadan-offer") {
      checkEgypt = false;
      break;
    }
}

const i18n = createI18n({
  locale: checkEgypt ? "arb" : "en",
  fallbackLocale: "en",
  messages,
});

export default i18n;
